import * as React from 'react';
import { useState, useMemo } from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import ServiceFactory from '../../../../services/serviceFactory';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface GpsValidationProps {
	snrMinMax: [number, number];
	satellitesMinMax: [number, number];
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	GPS_SNR = 'gpsSnr',
	SATELLITES = 'satellites'
}

const GpsValidation: React.FC<GpsValidationProps> = (props) => {
	const TEST_NAME: TestKey = 'gpsValidation';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.GPS_SNR, res ? res.data[FormKeys.GPS_SNR] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find((criteria) => criteria.fieldName === FormKeys.GPS_SNR);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.GPS_SNR}`);
						return false;
					}
					return validateTestCriteria(FormKeys.GPS_SNR, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(FormKeys.SATELLITES, res ? res.data[FormKeys.SATELLITES] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.SATELLITES
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.SATELLITES}`);
						return false;
					}
					return validateTestCriteria(FormKeys.SATELLITES, control.value, props.testCriteria);
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	return (
		<Box className={'rsGpsValidation'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
			<LabelInputText
				labelTitle={'GPS SNR (dB)'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.GPS_SNR)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME,
						undefined,
						undefined,
						props.testCriteria
					);
				}}
			/>
			<LabelInputText
				labelTitle={'Satellites'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.SATELLITES)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME,
						undefined,
						undefined,
						props.testCriteria
					);
				}}
			/>
		</Box>
	);
};
export default GpsValidation;
