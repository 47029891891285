import { PartAssemblyType } from '../assembly/IAssemblyService';
import { Fixture, TestFixture, TestFixtureType } from './ITestFixtureService';

export const testFixtures: { [key in TestFixtureType]: TestFixture } = {
	MILITARY_AIR_VEHICLE_TEST_FIXTURE: {
		label: 'Military Air Vehicle Test Fixture',
		partNumbers: ['TEST-4247476']
	},
	AIR_VEHICLE_BENCH_FIXTURE: {
		label: 'Air Vehicle Bench Fixture',
		partNumbers: ['TEST-4248661']
	},
	AIR_VEHICLE_BURN_FIXTURE: {
		label: 'Air Vehicle Burn Fixture',
		partNumbers: ['BURN-4248661']
	},
	CHASSIS_FIXTURE: {
		label: 'Chassis Fixture',
		partNumbers: ['TEST-4248220']
	},
	PAYLOAD_FIXTURE: {
		label: 'Payload Fixture',
		partNumbers: ['TEST-4246560']
	},
	PAYLOAD_FIXTURE_NG: {
		label: 'NG Payload Fixture',
		partNumbers: ['TEST-4246556']
	},
	CENTERSTAGE_FIXTURE: {
		label: 'Centerstage Fixture',
		partNumbers: ['TEST-4250046']
	},
	PRELIMINARY_CHECKOUT_FIXTURE: {
		label: 'Preliminary Checkout Fixture',
		partNumbers: ['TEST-4247476-PRELIM']
	}
};

const ngPayloadFixtureTestDefinition: Fixture = {
	name: 'NG Payload Bench Test',
	scriptName: 'payloadBench',
	tests: [
		{
			label: 'Check Firmware Version',
			testName: 'checkFirmware',
			pythonArgs: ['-t', 'checkFirmware', '-n'],
			auditRequired: true
		},
		{
			label: 'Verify Hardware Versions',
			testName: 'verifyHwVersions',
			pythonArgs: ['-t', 'verifyHwVersions', '-n'],
			auditRequired: true
		},
		{
			label: 'Update Part Number',
			testName: 'partNumber',
			pythonArgs: ['-t', 'partNumber', '-n'],
			auditRequired: true
		},
		{
			label: 'Friction',
			testName: 'friction',
			pythonArgs: ['-t', 'friction', '-n'],
			auditRequired: true
		},
		{
			label: 'Balance',
			testName: 'balance',
			pythonArgs: ['-t', 'balance', '-n'],
			auditRequired: true
		},
		{
			label: 'Check PPS',
			testName: 'checkPps',
			pythonArgs: ['-t', 'checkPps', '-n'],
			auditRequired: true
		},
		{
			label: 'Check SD Card',
			testName: 'checkSdCard',
			pythonArgs: ['-t', 'checkSdCard', '-n'],
			auditRequired: true
		},
		{
			label: 'Video Quality',
			testName: 'videoQuality',
			pythonArgs: ['-t', 'videoQuality', '-n'],
			auditRequired: true
		},
		{
			label: 'Inertial Point',
			testName: 'inertialPoint',
			pythonArgs: ['-t', 'inertialPoint', '-n'],
			auditRequired: true
		},
		{
			label: 'Check CTD',
			testName: 'checkCtd',
			pythonArgs: ['-t', 'checkCtd', '-n'],
			auditRequired: true
		}
	]
};

const payloadTestFixtureTestDefinition: Fixture = {
	name: 'Payload Bench Test',
	scriptName: 'payloadBench',
	tests: [
		{
			label: 'Load Firmware',
			testName: 'loadFirmware',
			pythonArgs: ['-t', 'loadFirmware'],
			auditRequired: true
		},
		{
			label: 'Comms: Mission Manager to Gimbal Controller',
			testName: 'commsMMtoGC',
			pythonArgs: ['-t', 'commsMMtoGC'],
			auditRequired: true
		},
		{
			label: 'Verify Hardware Versions',
			testName: 'verifyHwVersions',
			pythonArgs: ['-t', 'verifyHwVersions'],
			auditRequired: true
		},
		{
			label: 'Mechanical Zero Encoders',
			testName: 'mechZeroEncoders',
			pythonArgs: ['-t', 'mechZeroEncoders'],
			auditRequired: true
		},
		{
			label: 'Electrical Zero Encoders',
			testName: 'elecZeroEncoders',
			pythonArgs: ['-t', 'elecZeroEncoders'],
			auditRequired: true
		},
		{
			label: 'Check TMD Heaters',
			testName: 'checkTmdHeaters',
			pythonArgs: ['-t', 'checkTmdHeaters'],
			auditRequired: false
		},
		{
			label: 'PIPS Serial',
			testName: 'pipsSerial',
			pythonArgs: ['-t', 'pipsSerial'],
			auditRequired: true
		},
		{
			label: 'Friction',
			testName: 'friction',
			pythonArgs: ['-t', 'friction'],
			auditRequired: true
		},
		{
			label: 'Balance',
			testName: 'balance',
			pythonArgs: ['-t', 'balance'],
			auditRequired: true
		},
		{
			label: 'Electrical Zero Refine',
			testName: 'elecZeroRefine',
			pythonArgs: ['-t', 'elecZeroRefine'],
			auditRequired: true
		},
		{
			label: 'Check Motors',
			testName: 'checkMotors',
			pythonArgs: ['-t', 'checkMotors'],
			auditRequired: true
		},
		{
			label: 'Check SD Card',
			testName: 'checkSdCard',
			pythonArgs: ['-t', 'checkSdCard'],
			auditRequired: true
		},
		{
			label: 'Check PPS',
			testName: 'checkPps',
			pythonArgs: ['-t', 'checkPps'],
			auditRequired: true
		},
		{
			label: 'Check VSI',
			testName: 'checkVsi',
			pythonArgs: ['-t', 'checkVsi'],
			auditRequired: true
		},
		{
			label: 'Check CTD',
			testName: 'checkCtd',
			pythonArgs: ['-t', 'checkCtd'],
			auditRequired: true
		},
		{
			label: 'Video Quality',
			testName: 'videoQuality',
			pythonArgs: ['-t', 'videoQuality'],
			auditRequired: true
		},
		{
			label: 'Inertial Point',
			testName: 'inertialPoint',
			pythonArgs: ['-t', 'inertialPoint'],
			auditRequired: true
		}
		// {
		// 	label: 'Feature Point (EO)',
		// 	testName: 'featurePointEo',
		// 	pythonArgs: ['-t', 'featurePoint', '-p1', '1', '-p2', 'eo', '-p3', '0', '-p4', '0']
		// },
		// {
		// 	label: 'Feature Point (IR)',
		// 	testName: 'featurePointIr',
		// 	pythonArgs: ['-t', 'featurePoint', '-p1', '1', '-p2', 'ir', '-p3', '0', '-p4', '0']
		// },
	]
};

const centerstageTestFixtureTestDefinition: Fixture = {
	name: 'Centerstage Test',
	scriptName: 'payloadCenter',
	tests: [
		{
			label: 'Check IMU',
			testName: 'checkImu',
			pythonArgs: ['-t', 'checkImu'],
			auditRequired: true
		},
		{
			label: 'Check Cameras',
			testName: 'checkCameras',
			pythonArgs: ['-t', 'checkCameras'],
			auditRequired: true
		},
		{
			label: 'LRF Range',
			testName: 'lrfRange',
			pythonArgs: ['-t', 'lrfRange'],
			auditRequired: true
		},
		{
			label: 'HOB Cal',
			testName: 'hobCal',
			pythonArgs: ['-t', 'hobCal'],
			auditRequired: true
		}
	]
};

export const testFixtureTestMap: {
	[key in PartAssemblyType]: {
		[key in TestFixtureType]?: Fixture;
	};
} = {
	NG_FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE_NG: ngPayloadFixtureTestDefinition
	},
	NG_PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE_NG: ngPayloadFixtureTestDefinition
	},
	PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_PENETRATOR_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_FRAG_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	TRAINER_PAYLOAD_ASSEMBLY: {
		PAYLOAD_FIXTURE: payloadTestFixtureTestDefinition
	},
	INERT_FRAG_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	INERT_PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	PENETRATOR_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	FRAG_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	TRAINER_CENTERSTAGE_ASSEMBLY: {
		CENTERSTAGE_FIXTURE: centerstageTestFixtureTestDefinition
	},
	MILITARY_AIR_VEHICLE_ASSEMBLY: {
		MILITARY_AIR_VEHICLE_TEST_FIXTURE: {
			name: 'Military Vehicle Bench Test',
			scriptName: 'vehicleBench',
			tests: [
				{
					label: 'Write SN',
					testName: 'writeSn',
					pythonArgs: ['-t', 'writeSn'],
					auditRequired: true
				},
				{
					label: 'Load Firmware',
					testName: 'loadFirmware',
					pythonArgs: ['-t', 'loadFirmware'],
					auditRequired: true
				}
			]
		}
	},
	AIR_VEHICLE_ASSEMBLY: {
		AIR_VEHICLE_BENCH_FIXTURE: {
			name: 'Air Vehicle Bench Test',
			scriptName: 'vehicleBench',
			tests: [
				{
					label: 'Check NX Comms',
					testName: 'checkNxComms',
					pythonArgs: ['-t', 'checkNxComms'],
					auditRequired: true
				},
				{
					label: 'Check UART Comms',
					testName: 'checkUartComms',
					pythonArgs: ['-t', 'checkUartComms'],
					auditRequired: true
				},
				{
					label: 'Write SN',
					testName: 'writeSn',
					pythonArgs: ['-t', 'writeSn'],
					auditRequired: true
				},
				{
					label: 'Verify Radio',
					testName: 'verifyRadio',
					pythonArgs: ['-t', 'verifyRadio'],
					auditRequired: true
				},
				{
					label: 'Radio Comm',
					testName: 'radioComm',
					pythonArgs: ['-t', 'radioComm'],
					auditRequired: true
				},
				{
					label: 'Check PPS to GC',
					testName: 'checkPpsGc',
					pythonArgs: ['-t', 'checkPpsGc'],
					auditRequired: true
				},
				{
					label: 'Umbilical Modes',
					testName: 'umbilicalModes',
					pythonArgs: ['-t', 'umbilicalModes'],
					auditRequired: true
				},
				{
					label: 'Payload Eeprom',
					testName: 'payloadEeprom',
					pythonArgs: ['-t', 'payloadEeprom'],
					auditRequired: true
				},
				{
					label: 'Check Motors',
					testName: 'checkMotors',
					pythonArgs: ['-t', 'checkMotors'],
					auditRequired: true
				},
				{
					label: 'Check GPS',
					testName: 'checkGps',
					pythonArgs: ['-t', 'checkGps'],
					auditRequired: true
				},
				{
					label: 'Check Mag Baro',
					testName: 'checkMagBaro',
					pythonArgs: ['-t', 'checkMagBaro'],
					auditRequired: true
				},
				{
					label: 'Check Payload Video',
					testName: 'checkPayloadVideo',
					pythonArgs: ['-t', 'checkPayloadVideo'],
					auditRequired: true
				},
				{
					label: 'Check Payload SD Card',
					testName: 'checkSdCard',
					pythonArgs: ['-t', 'checkSdCard'],
					auditRequired: true
				},
				{
					label: 'Check VTO to ESAF',
					testName: 'esafVto',
					pythonArgs: ['-t', 'esafVto'],
					auditRequired: true
				}
			]
		},
		AIR_VEHICLE_BURN_FIXTURE: {
			name: 'Air Vehicle Burn In Test',
			scriptName: 'vehicleBurnIn',
			tests: [
				{
					label: 'Run Throttle Profile',
					testName: 'runThrottleProfile',
					pythonArgs: ['-t', 'runThrottleProfile'],
					auditRequired: true
				}
			]
		},
		PRELIMINARY_CHECKOUT_FIXTURE: {
			name: 'Preliminary Checkout',
			scriptName: 'prelimCheckout',
			tests: [
				{
					label: 'Verify HWID',
					testName: 'verifyHwid',
					pythonArgs: ['-t', 'verifyHwid'],
					auditRequired: true
				},
				{
					label: 'Radio Multipoint',
					testName: 'radioMultipoint',
					pythonArgs: ['-t', 'radioMultipoint'],
					auditRequired: true
				},
				{
					label: 'Check Radio PPS',
					testName: 'checkRadioPps',
					pythonArgs: ['-t', 'checkRadioPps'],
					auditRequired: true
				},
				{
					label: 'Write Vehicle Name',
					testName: 'writeVehicleName',
					pythonArgs: ['-t', 'writeVehicleName'],
					auditRequired: true
				}
			]
		}
	},
	CHASSIS_MAIN_ASSEMBLY: {
		CHASSIS_FIXTURE: {
			name: 'Chassis Test',
			scriptName: 'vehicleChassis',
			tests: [
				{
					label: 'Configure FTDI',
					testName: 'configFtdi',
					pythonArgs: ['-t', 'configFtdi'],
					auditRequired: true
				},
				{
					label: 'Load Mission Mgr Firmware',
					testName: 'loadMissionMgrFirmware',
					pythonArgs: ['-t', 'loadMissionMgrFirmware'],
					auditRequired: true
				},
				{
					label: 'Load PX4 Firmware',
					testName: 'loadPx4Firmware',
					pythonArgs: ['-t', 'loadPx4Firmware'],
					auditRequired: true
				},
				{
					label: 'Load NX Firmware',
					testName: 'loadNxFirmware',
					pythonArgs: ['-t', 'loadNxFirmware'],
					auditRequired: true
				},
				{
					label: 'Check Comms',
					testName: 'checkComms',
					pythonArgs: ['-t', 'checkComms'],
					auditRequired: true
				},
				{
					label: 'Check IMU',
					testName: 'checkImu',
					pythonArgs: ['-t', 'checkImu'],
					auditRequired: true
				},
				{
					label: 'HW Version Check',
					testName: 'hwVersionCheck',
					pythonArgs: ['-t', 'hwVersionCheck'],
					auditRequired: true
				},
				{
					label: 'Check LRF',
					testName: 'checkLrf',
					pythonArgs: ['-t', 'checkLrf'],
					auditRequired: true
				},
				{
					label: 'Radio Firmware',
					testName: 'radioFirmware',
					pythonArgs: ['-t', 'radioFirmware'],
					auditRequired: true
				},
				{
					label: 'Check Radio License',
					testName: 'checkRadioLicense',
					pythonArgs: ['-t', 'checkRadioLicense'],
					auditRequired: true
				},
				{
					label: 'Check Radio',
					testName: 'checkRadio',
					pythonArgs: ['-t', 'checkRadio'],
					auditRequired: true
				},
				{
					label: 'Nav Video Quality',
					testName: 'videoQuality',
					pythonArgs: ['-t', 'videoQuality'],
					auditRequired: true
				},
				{
					label: 'Pixhawk Voltage Sense',
					testName: 'px4VoltageSense',
					pythonArgs: ['-t', 'px4VoltageSense'],
					auditRequired: false
				},
				{
					label: 'Check Pixhawk SD Card',
					testName: 'checkPxSd',
					pythonArgs: ['-t', 'checkPxSd'],
					auditRequired: true
				},
				{
					label: 'Battery Wake',
					testName: 'batteryWake',
					pythonArgs: ['-t', 'batteryWake'],
					auditRequired: true
				},
				{
					label: 'Check VTO',
					testName: 'checkVto',
					pythonArgs: ['-t', 'checkVto'],
					auditRequired: true
				},
				{
					label: 'Check GPS PPS',
					testName: 'checkGpsPps',
					pythonArgs: ['-t', 'checkGpsPps'],
					auditRequired: true
				},
				{
					label: 'Check ESC',
					testName: 'checkEsc',
					pythonArgs: ['-t', 'checkEsc'],
					auditRequired: true
				},
				{
					label: 'Check NX Fan',
					testName: 'checkFan',
					pythonArgs: ['-t', 'checkFan'],
					auditRequired: true
				}
				// {
				// 	label: 'Check IMU',
				// 	testName: 'checkImu',
				// 	pythonArgs: ['-t', 'checkImu']
				// },
				// {
				// 	label: 'Check Fan',
				// 	testName: 'checkFan',
				// 	pythonArgs: ['-t', 'checkFan']
				// },

				// {
				// 	label: 'Calibrate Cameras',
				// 	testName: 'calCameras',
				// 	pythonArgs: ['-t', 'calCameras']
				// }
			]
		}
	},
	AIRFRAME_ASSEMBLY: {},
	MAIN_BOARD_ASSEMBLY: {},
	FCU_ASSEMBLY: {},
	FCU_ANTENNA_ASSEMBLY: {},
	BATTERY_ASSEMBLY: {},
	CHARGER_ASSEMBLY: {},
	CONFORMAL_PACKAGING: {},
	GROUND_CONTROL_STATION_KIT: {},
	R1_MISSILE_SYSTEM_KIT_INERT: {},
	R1_MISSILE_AUR_EFP_WARHEAD: {},
	R1_MISSILE_AUR_FRAG_T_WARHEAD: {},
	R1_MISSILE_AUR_TRAINING_WARHEAD: {},
	R1_MISSILE_AUR_INERT_WARHEAD: {},
	R1_MISSILE_AUR_INERT_IN_CONTAINER: {},
	R1_MISSILE_AUR_FRAG_T_IN_CONTAINER: {},
	R1_MISSILE_AUR_EFP_IN_CONTAINER: {}
};
