import { Box, Button, Label, Paper, Popup, PopupProps, rsToastify } from '@redskytech/framework/ui';
import { WebUtils } from '@redskytech/framework/utils';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import KitCheckoutPdf from '../../components/kitCheckoutPdf/KitCheckoutPdf';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { SummaryData } from '../../pages/assemblyDetailsPage/testSummaryTable/TestSummaryTable';
import { fcuTestKeys } from '../../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../../pages/magTestPage/MagTestSection';
import {
	fcuPartNumbers,
	livePayloadPartNumbers,
	trainerPayloadPartNumbers
} from '../../services/assembly/assembly.data';
import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import serviceFactory from '../../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../../services/testFixture/testFixture.data';
import { getAllTestDataByPartId } from '../../utils/generateAuditList';
import { generatePdf } from '../../utils/pdfUtil';
import { StringUtils } from '../../utils/utils';
import './PdfPopup.scss';

export interface PdfPopupProps extends PopupProps {
	summaryData: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
	serialNumber: string;
	salesOrder: string;
}

const PdfPopup: React.FC<PdfPopupProps> = (props) => {
	const assemblyService = new AssemblyService();
	const expectedTestNamesRef = useRef<string[]>([]);
	const [groupedSummaryData, setGroupedSummaryData] = useState<
		{ group: KitGroupType; idList: HardwareIdDecoded[] }[]
	>([]);

	useEffect(() => {
		(async function fetchSummaryData() {
			const summaries: { group: KitGroupType; idList: HardwareIdDecoded[] }[] = [];
			if (!props.summaryData) return;

			for (let group of props.summaryData) {
				const groupTypeOptions: Partial<KitGroupType>[] = ['FCU_ANTENNA', 'PAYLOAD', 'VEHICLE'];

				if (groupTypeOptions.includes(group.group)) {
					await Promise.all(
						group.idList.map(async (item) => {
							if (!item.partId) return;
							const { familyTree } = await ApiRequestV1.getPartFamilyTree({ partId: item.partId });
							await processFamilyTreeItem(familyTree, summaries, group.group);
						})
					);
				}
			}
			setGroupedSummaryData(summaries);
		})();
	}, [props.summaryData]);

	async function processFamilyTreeItem(
		item: CustomTypes.PartFamilyTreeResponse['familyTree'],
		summaries: any[],
		groupName: string
	) {
		if (!item) return;

		const test = await getTest(item.partNumber);
		let summaryData = (await getGroupedSummaryData(item.id, test)) || [];
		const missingTest = await getAllTestDataByPartId(item.id);

		let partLabel = assemblyService.getLabelFromPartNumber(item.partNumber);
		let missingTestGroup = missingTest.find((item) => item.group === partLabel);
		if (missingTestGroup) {
			for (let missingTestItem of missingTestGroup.tests) {
				for (let testName of Object.keys(test) as (keyof typeof test)[]) {
					if (test[testName].includes(StringUtils.toCamelCase(missingTestItem.testName))) {
						let index = summaryData.findIndex((summaryDataItem) => summaryDataItem.group === testName);
						if (index < 0) {
							summaryData.push({
								group: testName.toString(),
								tests: [missingTestItem]
							});
							continue;
						}

						if (!summaryData[index].tests) {
							summaryData[index].tests = [missingTestItem];
							continue;
						}
						if (summaryData[index].tests.some((testItem) => testItem.testName === missingTestItem.testName))
							continue;

						summaryData[index].tests.push(missingTestItem);
						break;
					}
				}
			}
		}

		if (summaryData.length) {
			summaries.push({
				name: `${item.partNumber} - ${assemblyService.getLabelFromPartNumber(item.partNumber)}, ${
					item.serialNumber
				}`,
				testGroup: [...summaryData]
			});
		}

		for (const child of item.children) {
			await processFamilyTreeItem(child, summaries, groupName);
		}
	}

	async function getTest(partNumber: string): Promise<{ [key: string]: string[] }> {
		const assemblyService = serviceFactory.get('AssemblyService');
		const discoveredPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(partNumber);

		let testNames: string[] = [];
		let testGroups: any = {};
		if (discoveredPartAssemblyType && testFixtureTestMap.hasOwnProperty(discoveredPartAssemblyType)) {
			let fixture: TestFixtureType;
			for (fixture in testFixtureTestMap[discoveredPartAssemblyType]) {
				if (
					!testFixtureTestMap[discoveredPartAssemblyType].hasOwnProperty(fixture) ||
					optionalTestFixtures.includes(fixture)
				)
					continue;

				testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name] = [];
				for (let test of testFixtureTestMap[discoveredPartAssemblyType][fixture]!.tests) {
					testGroups[testFixtureTestMap[discoveredPartAssemblyType][fixture]!.name].push(test.testName);
					testNames.push(test.testName);
				}
			}
		}

		const addTests = (groupName: string, tests: string[]) => {
			testGroups[groupName] = tests;
			testNames.push(...tests);
		};

		if (trainerPayloadPartNumbers.includes(partNumber)) {
			addTests('Trainer Payload Tests', trainerPayloadTestKeys);
		}

		if (livePayloadPartNumbers.includes(partNumber)) {
			addTests('Live Payload Tests', livePayloadTestKeys);
		}

		if (fcuPartNumbers.includes(partNumber)) {
			addTests('FCU Tests', fcuTestKeys);
		}

		// For vehicles
		if (discoveredPartAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			addTests('Flight Test', vehicleTestKeys);
			addTests('Magnetometer Calibration', magTestKeys);
		}

		return testGroups;
	}

	async function getGroupedSummaryData(partId: number, testGroups: { [key: string]: string[] }) {
		try {
			const res = await ApiRequestV1.getTestResultSummary({ partId });

			let summaryData: SummaryData[] = [];
			let missingTests = [...expectedTestNamesRef.current];

			if (!res.summary) return;
			for (let i in res.summary) {
				missingTests = missingTests.filter((testName) => testName !== i);
				if (i === 'sessionStart') continue;
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(i);

				summaryData.push({
					testName: humanReadableTestName,
					status: res.summary[i].status,
					createdOn: res.summary[i].createdOn,
					testResultId: res.summary[i].testResultId
				});
			}

			// Add the missing tests
			for (let testName of missingTests) {
				let humanReadableTestName = StringUtils.convertCamelCaseToHuman(testName);
				summaryData.push({
					testName: humanReadableTestName,
					status: 'INCOMPLETE',
					createdOn: '',
					testResultId: 0
				});
			}

			let groupedSummaryData: {
				group: string;
				tests: {
					testName: string;
					status: 'PASS' | 'FAIL' | 'INCOMPLETE' | 'MISSING';
					createdOn: string;
					testResultId: number;
				}[];
			}[] = [];

			for (let group in testGroups) {
				testGroups[group].forEach((testName) => {
					let test = summaryData.find(
						(test) => test.testName === StringUtils.convertCamelCaseToHuman(testName)
					);
					if (test) {
						let groupIndex = groupedSummaryData.findIndex((groupedTest) => groupedTest.group === group);
						if (groupIndex === -1) {
							groupedSummaryData.push({
								group: group,
								tests: [test]
							});
						} else {
							groupedSummaryData[groupIndex].tests.push(test);
						}
					}
				});
			}

			return groupedSummaryData;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	return (
		<Popup {...props}>
			<Paper className="rsPdfPopup">
				<Box display="flex" justifyContent="space-between" borderBottom={'1px solid black'}>
					<Label variant="h1" weight="bold">
						Kit Checkout
					</Label>
					<Button
						look="containedPrimary"
						onClick={() => {
							generatePdf(
								document.querySelector('.rsKitCheckoutPdf') as HTMLElement,
								props.serialNumber,
								groupedSummaryData
							);
						}}
					>
						Download PDF
					</Button>
				</Box>
				<KitCheckoutPdf
					serialNumber={props.serialNumber}
					salesOrder={props.salesOrder}
					summaries={groupedSummaryData}
					kitCheckoutGroup={props.summaryData}
				/>
			</Paper>
		</Popup>
	);
};

export default PdfPopup;
