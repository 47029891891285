import { Box, Icon, Label } from '@redskytech/framework/ui';
import './CommentBox.scss';
import * as React from 'react';

export interface CommentBoxProps {
	onDelete: () => void;
	subject: string;
	comment: string;
}

const CommentBox: React.FC<CommentBoxProps> = (props) => {
	return (
		<Box className={'rsCommentBox'}>
			<Box display="flex" justifyContent="space-between">
				<Label variant={'subheader2'} weight={'bold'}>
					{props.subject}
				</Label>
				<Icon iconImg={'icon-delete'} onClick={props.onDelete} cursorPointer />
			</Box>

			<Label variant={'body1'} weight="regular">
				{props.comment}
			</Label>
		</Box>
	);
};
export default CommentBox;
