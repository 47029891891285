import {
	Box,
	Button,
	LabelInputText,
	LabelInputTextarea,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import * as React from 'react';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import './AddCommentPopup.scss';
import { useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

export interface AddCommentPopupProps extends PopupProps {
	subject: string;
	comment?: string;
	onSave: (comment: string) => void;
}

enum FormKeys {
	TEST_NAME = 'testName',
	COMMENT = 'comment'
}

const AddCommentPopup: React.FC<AddCommentPopupProps> = (props) => {
	const [commentFormGroup, setCommentFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TEST_NAME, props.subject, []),
			new RsFormControl<string>(FormKeys.COMMENT, props.comment || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Comment is required')
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setCommentFormGroup(commentFormGroup.clone().update(control));
	}

	async function handleCommentSave() {
		if (!(await commentFormGroup.isValid())) {
			setCommentFormGroup(commentFormGroup.clone());
			return;
		}

		const { comment } = commentFormGroup.toModel<{ testName: string; comment: string }>();

		props.onSave(comment);
		popupController.close(AddCommentPopup);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddCommentPopup'}>
				<PopupHeader
					title={`Add a comment`}
					onClose={() => {
						popupController.close(AddCommentPopup);
					}}
				/>
				<Box display={'grid'} gap={8}>
					<LabelInputText
						labelTitle="Subject"
						inputMode="text"
						control={commentFormGroup.get<string>(FormKeys.TEST_NAME)}
						readOnly
					/>
					<LabelInputTextarea
						labelTitle="Comment"
						inputMode="text"
						control={commentFormGroup.get<string>(FormKeys.COMMENT)}
						updateControl={handleUpdateControl}
					/>
					<Button look="containedPrimary" onClick={handleCommentSave} ml={'auto'}>
						Save
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};

export default AddCommentPopup;
