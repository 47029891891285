import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const tffPaths = [
	'/pdfStatic/fonts/icomoon.ttf',
	'/pdfStatic/fonts/Barlow-Regular.ttf',
	'/pdfStatic/fonts/Barlow-Bold.ttf'
];
/**
 * This function will generate a pdf with the screenshot of the element.
 * @param element
 * @param fileName
 */
export async function generatePdfWithScreenShot(element: HTMLElement, fileName: string) {
	const canvas = await html2canvas(element);
	const imgData = canvas.toDataURL('image/png');
	const pdf = new jsPDF('p', 'mm', 'a4');
	const width = pdf.internal.pageSize.getWidth();
	const height = pdf.internal.pageSize.getHeight();
	pdf.addImage(imgData, 'PNG', 0, 0, width, height);
	pdf.save(fileName);
}

export function generatePdf(element: HTMLElement, fileName: string, kitSummaryData: any[] = []) {
	const html = `
				<!DOCTYPE html>
				<html lang="en">
				  <head>
				    <meta charset="UTF-8" />
				    <title>${fileName}</title>
				    <!-- Include jsPDF and html2canvas libraries -->
				    <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>
				    <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js"></script>
					<link rel="preconnect" href="https://fonts.googleapis.com" />
				    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				    <link
				      href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap"
				      rel="stylesheet"
				    />
					<link rel="stylesheet" href="/pdfStatic/fonts/icomoon/style.css">
					<link rel="stylesheet" href="/pdfStatic/style.css" />

				  </head>
				 <body>
				 	<div class="rsKitCheckoutPdf">
				 		${element.innerHTML} 
				 	</div>
					<script>
					console.time("Generate-PDF-Start");
					//	const tffPaths = [
					//		'/pdfStatic/fonts/icomoon.ttf',
					//		'/pdfStatic/fonts/Barlow-Regular.ttf',
					//		'/pdfStatic/fonts/Barlow-Bold.ttf']

						const kitTestSummaryData = JSON.parse('${JSON.stringify(kitSummaryData)}');


						async function generatePdf() {
							const { jsPDF } = window.jspdf;
							const pdf = new jsPDF('p', 'mm', 'a4');

					//		 const fontsBase64 = await convertFontsToBase64(tffPaths);
		 			//		for (const [fontName, base64String] of Object.entries(fontsBase64)) {
					//		 	pdf.addFileToVFS(fontName + '.ttf', base64String);
					//		 	pdf.addFont(fontName + '.ttf', fontName, 'normal');
					//		 }

					//		pdf.setFont('Barlow-Regular');


							let newElement = document.querySelector('.rsKitCheckoutPdf').cloneNode(true);
							const pdfHeader = newElement.querySelector('.pdfHeader');
							const rsKitCheckoutTable = Array.from(newElement.querySelectorAll('.rsKitCheckoutTable'));
							

							let newElementHeader = document.createElement('div');
							newElementHeader.classList.add('rsKitCheckoutPdf');
							newElementHeader.appendChild(pdfHeader);
							for (let i = 0; i < rsKitCheckoutTable.length; i++) {
								newElementHeader.appendChild(rsKitCheckoutTable[i]);
							}

							await pdf.html(newElementHeader, {
								callback: function () {
          					        pdf.addPage();
          					    },
								autoPaging: true,
								x: 0,
								y: 0,
								width: pdf.internal.pageSize.getWidth(),
								windowWidth: 612
							});


							let htmlPages = [];
							 // Generate HTML content for each page
          					for (let i = 1; i <= kitTestSummaryData.length; i++) {
          					  let element = document.createElement("div");
          					  element.classList.add("rsKitCheckoutPdf");

							element.innerHTML = \`<div class="summaryTable">
      				                     <h6 style="margin-bottom: 4px;"> \` + kitTestSummaryData[i - 1].name + \`
      				                     </h6>
      				                     <div class="testTableWrapper">\` + renderTestSummaryTables(kitTestSummaryData[i - 1].testGroup) + \`</div>
										 <div style="break-after: page"></div> 
										 </div>
									 \`;

          					  htmlPages.push(element);
          					}

          					// Function to add each HTML page to the PDF
          					function addPageToPDF(index) {
          					  if (index >= htmlPages.length) {
          					    // Save the PDF after all pages are added
          					    pdf.save('${fileName}' + '.pdf');
								console.timeEnd("Generate-PDF-Start");
          					    return;
          					  }

          					  pdf.html(htmlPages[index], {
          					    html2canvas: {
          					      // scale: 0.2, // Adjusts the scale for better performance
          					      // removeContainer: true, // Removes the iframe after rendering to save memory
          					    },
          					    callback: function () {
          					      if (index < htmlPages.length - 1) {
          					        pdf.addPage();
          					      }
          					      // Process the next page
          					      addPageToPDF(index + 1);
          					    },
          					    x: 0,
          					    y: pdf.getPageHeight() * (pdf.getNumberOfPages() - 1),
          					    width: pdf.internal.pageSize.getWidth(),
          					    windowWidth: 612,
          					  });
          					}

          					// Start adding pages to the PDF
          					addPageToPDF(0);
						}

						async function convertFontsToBase64(fontPaths) {
							const fontPromises = fontPaths.map(async (fontPath) => {
								const response = await fetch(fontPath);
								const blob = await response.blob();
								return new Promise((resolve, reject) => {
									const reader = new FileReader();
									reader.onloadend = () => {
										const base64String = reader.result.split(',')[1];
										resolve(base64String);
									};
									reader.onerror = reject;
									reader.readAsDataURL(blob);
								});
							});
						
							const fontBase64Strings = await Promise.all(fontPromises);
						
							return fontPaths.reduce((acc, path, index) => {
								// Get the full file name (including the extension) and use it as the key
								const fontFileName = path.split('/').pop();
								if (fontFileName) {
									acc[fontFileName] = fontBase64Strings[index];
								}
								return acc;
							}, {});
						}

						function renderTestSummaryTables(test) {
      					  return test.map((item) => {
      					    return \`<div class="testTableContent">
      					        <p class="testName rsLabel fwSemiBold caption1">\` + item.group + \`</p>
      					        <table>
      					          <thead>
      					            <tr class="header">
      					              <th>
      					                <p class="rsLabel fwRegular caption2">Test</p>
      					              </th>
      					              <th>
      					                <p class="rsLabel fwRegular caption2">Tested On</p>
      					              </th>
      					              <th>
      					                <p class="rsLabel fwRegular caption2">Status</p>
      					              </th>
      					            </tr>
      					          </thead>
      					          <tbody>\` + renderTestSummaryRows(item.tests) + \`</tbody>
      					        </table>
      					      </div>\`;
      					  });
      					}

      					function renderTestSummaryRows(subTest) {
      					  return subTest.map((row) => {
      					    return \`<tr>
      					        <td>
      					          <p class="rsLabel fwRegular caption2">\` + row.testName + \`</p>
      					        </td>
      					        <td>
      					          <p class="rsLabel fwRegular caption2">\` + new Date(row.createdOn).toLocaleDateString() + \`</p>
      					            
      					        </td>
      					        <td>
      					          <p class="caption2">\` + renderStatusChip(row.status) + \`</p>
      					        </td>
      					      </tr>\`;
      					  });
      					}

      					function renderStatusChip(status) {
      					  if (status === "INCOMPLETE") {
      					    return statusChip("WARNING");
      					  } else if (status === "MISSING") {
      					    return statusChip("INCOMPLETE");
      					  } else if (status === "FAIL") {
      					    return statusChip("ERROR");
      					  } else {
      					    return statusChip("SUCCESS");
      					  }
      					}

      					 function statusChip(status) {
      					 	 const StatusMap = {
      					 	   ERROR: { name: "Fail", iconImg: "icon-fail", color: "#750800;" },
      					 	   SUCCESS: { name: "Pass", iconImg: "icon-check", color: "#20a612;" },
      					 	   WARNING: {
      					 	     name: "Incomplete",
      					 	     iconImg: "icon-warning",
      					 	     color: "#e6900f;",
      					 	   },
      					 	   INFO: { name: "Pass", iconImg: "icon-info", color: "#1469b8;" },
      					 	   PENDING: {
      					 	     name: "Incomplete",
      					 	     iconImg: "icon-pending",
      					 	     color: "#495057;",
      					 	   },
      					 	   COMPLETE: {
      					 	     name: "Complete",
      					 	     iconImg: "icon-check",
      					 	     color: "#20a612;",
      					 	   },
      					 	   INCOMPLETE: {
      					 	     name: "Incomplete",
      					 	     iconImg: "icon-warning",
      					 	     color: "#e6900f;",
      					 	   },
      					 	   AUDITPASS: {
      					 	     name: "Pass",
      					 	     iconImg: "icon-check-circle",
      					 	     color: "#20a612;",
      					 	   },
      					 	 };
						
      					 	 return (
      					 	   \`<p class="rsLabel caption1 fwRegular" style="color: \` + StatusMap[status === "WARNING" ? "PENDING" : status].color + \`"> \` + StatusMap[status].name + \`</p>\`
							   )
      					 	 
      					}

						//	generatePdf();
					</script>
				 </body>
				 </html>
  				`;

	console.log('html: ', html);

	const newWindow = window.open();
	newWindow?.document.write(html);
	newWindow?.document.close();
	newWindow?.print();
}

// export async function generatePdf(element: HTMLElement, fileName: string) {
// 	const pdf = new jsPDF('p', 'mm', 'a4');

// 	const fontsBase64 = await convertFontsToBase64(tffPaths);

// 	for (const [fontName, base64String] of Object.entries(fontsBase64)) {
// 		pdf.addFileToVFS(`${fontName}.ttf`, base64String);
// 		pdf.addFont(`${fontName}.ttf`, fontName, 'normal');
// 	}

// 	pdf.setFont('Barlow-Regular');

// 	let newElement = element.cloneNode(true) as HTMLElement;
// 	const pdfHeader = newElement.querySelector('.pdfHeader');
// 	const rsKitCheckoutTable = Array.from(newElement.querySelectorAll('.rsKitCheckoutTable'));
// 	const summaryTables = Array.from(newElement.querySelectorAll('.summaryTable'));

// 	let newElementHeader = document.createElement('div');
// 	newElementHeader.classList.add('rsKitCheckoutPdf');
// 	newElementHeader.appendChild(pdfHeader as HTMLElement);
// 	for (let i = 0; i < rsKitCheckoutTable.length; i++) {
// 		newElementHeader.appendChild(rsKitCheckoutTable[i] as HTMLElement);
// 	}
// 	pdf.html(newElementHeader, {
// 		autoPaging: true,
// 		x: 0,
// 		y: 0,
// 		width: pdf.internal.pageSize.getWidth(),
// 		windowWidth: element.scrollWidth
// 	});

// 	for (let i = 0; i < summaryTables.length; i++) {
// 		pdf.addPage();
// 		let newElementTable = document.createElement('div');
// 		newElementTable.classList.add('rsKitCheckoutPdf');
// 		newElementTable.appendChild(summaryTables[i] as HTMLElement);
// 		pdf.html(newElementTable, {
// 			autoPaging: true,
// 			x: 0,
// 			//@ts-ignore
// 			y: pdf.getPageHeight() * (pdf.getNumberOfPages() - 1),
// 			width: pdf.internal.pageSize.getWidth(),
// 			windowWidth: element.scrollWidth
// 		});
// 	}

// const blob = pdf.output('blob');
// const url = URL.createObjectURL(blob);
// const link = document.createElement('a');
// link.target = '_blank';
// link.href = url;
// link.download = fileName;
// document.body.appendChild(link);
// link.click();
// document.body.removeChild(link);
// }

//  export async function generatePdf(element: HTMLElement, fileName: string) {
// 	const doc = new jsPDF('p', 'mm', 'a4');
// 	const htmlPages: HTMLElement[] = [];

// 	let newElement = element.cloneNode(true) as HTMLElement;
// 	const summaryTables = Array.from(newElement.querySelectorAll('.summaryTable'));

// 	for (let i = 1; i <= summaryTables.length; i++) {
// 		let newElementTable = document.createElement('div');
// 		newElementTable.classList.add('rsKitCheckoutPdf');
// 		newElementTable.appendChild(summaryTables[i - 1] as HTMLElement);
// 		htmlPages.push(newElementTable);
// 	}

// 	function addPageToPDF(index: number) {
// 		if (index >= htmlPages.length) {
// 			doc.save(fileName);
// 			return;
// 		}
// 		doc.html(htmlPages[index], {
// 			html2canvas: {
// 			  // scale: 0.2, // Adjusts the scale for better performance
// 			  // removeContainer: true, // Removes the iframe after rendering to save memory
// 			},
// 			callback: function () {
// 			  if (index < htmlPages.length - 1) {
// 				doc.addPage();
// 			  }
// 			  // Process the next page
// 			  addPageToPDF(index + 1);
// 			},
// 			x: 0,
// 			//@ts-ignore
// 			y: doc.getPageHeight() * (doc.getNumberOfPages() - 1),
// 			width: doc.internal.pageSize.getWidth(),
// 		 	windowWidth: element.scrollWidth
// 		  });
// 	}

// 	addPageToPDF(0);
// }

/* export async function generatePdf(element: HTMLElement, fileName: string) {
	const pdf = new jsPDF('p', 'mm', 'a4');

	// const fontsBase64 = await convertFontsToBase64(tffPaths);

	// for (const [fontFileName, base64String] of Object.entries(fontsBase64)) {
	// 	pdf.addFileToVFS(fontFileName, base64String);

	// 	const fontName = fontFileName.split('.')[0];

	// 	pdf.addFont(fontFileName, fontName, 'normal');
	// }

	// pdf.setFont('barlow-regular');

	let newElement = element.cloneNode(true) as HTMLElement;
	const pdfHeader = newElement.querySelector('.pdfHeader');
	const rsKitCheckoutTable = Array.from(newElement.querySelectorAll('.rsKitCheckoutTable'));
	const summaryTables = Array.from(newElement.querySelectorAll('.summaryTable'));

	// let newElementHeader = document.createElement('div');
	// newElementHeader.classList.add('rsKitCheckoutPdf');
	// newElementHeader.appendChild(pdfHeader as HTMLElement);
	// for (let i = 0; i < rsKitCheckoutTable.length; i++) {
	// 	newElementHeader.appendChild(rsKitCheckoutTable[i] as HTMLElement);
	// }
	// await pdf.html(newElementHeader, {
	// 	autoPaging: true,
	// 	x: 0,
	// 	y: 0,
	// 	width: pdf.internal.pageSize.getWidth(),
	// 	windowWidth: element.scrollWidth
	// });

	let htmlPages: HTMLElement[] = [];
	// for (let i = 0; i <= summaryTables.length - 1; i++) {
	for (let i = 0; i <= 510 - 1; i++) {
		// let newElementTable = document.createElement('div');
		// newElementTable.classList.add('rsKitCheckoutPdf');
		// newElementTable.appendChild(summaryTables[i] as HTMLElement);

		let elementOne = document.createElement('div');
		// let elementTwo = document.createElement('p');

		// elementTwo.innerHTML = `
		// 	<div class="page-content">
		// 		<h1>Page ${i}</h1>
		// 		<p>
		// 			This is sample content for page ${i}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
		// 			Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh
		// 			elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.
		// 			Mauris massa. Vestibulum lacinia arcu eget nulla.
		// 		</p>
		// 		<div class="page-number">Page ${i} of ${50}</div>
		// 	</div>
		// `;

		// elementOne.appendChild(elementTwo);
		htmlPages.push(elementOne);
		// htmlPages.push(newElementTable);
	}

	function addSummaryTableToPDF(index: number) {
		if (index >= htmlPages.length) {
			pdf.save(fileName);
			return;
		}

		pdf.html(htmlPages[index], {
			html2canvas: { removeContainer: true },
			autoPaging: true,
			x: 0,
			//@ts-ignore
			y: pdf.getPageHeight() * (pdf.getNumberOfPages() - 1),
			width: pdf.internal.pageSize.getWidth(),
			windowWidth: element.scrollWidth,
			callback: function () {
				if (index < htmlPages.length - 1) {
					pdf.addPage();
				}

				addSummaryTableToPDF(index + 1);
			}
		});
	}

	// addSummaryTableToPDF(0);

	// // Now set the font
	// pdf.setFont('Barlow-Regular');

	const testElement = document.createElement('div');
	testElement.innerHTML = `<p>This is the first page.</p>`;
	async function test() {
		pdf.html(testElement, {
			html2canvas: { removeContainer: true },
			autoPaging: true,
			fontFaces: [
				{
					family: 'Open Sans, sans-serif',
					style: 'normal',
					weight: 'normal',
					src: [{ url: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap', format: 'truetype' }]
				}
			],
			x: 0,
			//@ts-ignore
			y: pdf.getPageHeight() * (pdf.getNumberOfPages() - 1),
			width: pdf.internal.pageSize.getWidth(),
			windowWidth: element.scrollWidth,
			callback: function () {
				pdf.addPage();
				pdf.text('This is the second page.', 30, 30);
				pdf.save('TestDoc');
			}
		});
	}

	await test();

	// // Add another page
}
 */
/* /**
 * Dont remove this function....
 * @param fontPaths
 * @returns
 */

// export async function generatePdf(element: HTMLElement, fileName: string) {
// 	const pdf = new jsPDF('p', 'mm', 'a4');

// 	const fontsBase64 = await convertFontsToBase64(tffPaths);

// 	for (const [fontName, base64String] of Object.entries(fontsBase64)) {
// 		pdf.addFileToVFS(`${fontName}.ttf`, base64String);
// 		pdf.addFont(`${fontName}.ttf`, fontName, 'normal');
// 	}

// 	pdf.setFont('Barlow-Regular');

// 	await pdf.html(element, {
// 		callback: function (pdf) {
// 			const blob = pdf.output('blob');
// 			const url = URL.createObjectURL(blob);
// 			const link = document.createElement('a');
// 			link.target = '_blank';
// 			link.href = url;
// 			link.download = fileName;
// 			document.body.appendChild(link);
// 			link.click();
// 			document.body.removeChild(link);
// 		},
// 		autoPaging: true,
// 		margin:[10,0,10,0],
// 		x: 0,
// 		y: 0,
// 		width: pdf.internal.pageSize.getWidth(),
// 		windowWidth: element.scrollWidth
// 	});
// }

async function convertFontsToBase64(fontPaths: string[]): Promise<Record<string, string>> {
	const fontPromises = fontPaths.map(async (fontPath) => {
		const response = await fetch(fontPath);
		const blob = await response.blob();
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = (reader.result as string).split(',')[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	});

	const fontBase64Strings = await Promise.all(fontPromises);

	return fontPaths.reduce((acc, path, index) => {
		// Get the full file name (including the extension) and use it as the key
		const fontFileName = path.split('/').pop();
		if (fontFileName) {
			acc[fontFileName] = fontBase64Strings[index];
		}
		return acc;
	}, {} as Record<string, string>);
}
