import * as React from 'react';
import { useEffect, useState } from 'react';
import './MagTestSection.scss';
import { Box, Button, Label, popupController, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../components/pageHeader/PageHeader';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import useWarnOnUnsavedChanges from '../../customHooks/useWarnOnUnsavedChanges';
import SelectableInputText from '../../components/selectableInputText/SelectableInputText';
import { TestStatus } from '../testDetailsPage/TestDetailsPage';
import { StringUtils, WebUtils } from '../../utils/utils';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { TestResult } from '../../services/testFixture/ITestFixtureService';
import LoadingPopup, { LoadingPopupProps } from '../../popups/loadingPopup/LoadingPopup';
import StatusChip from '../../components/statusChip/StatusChip';
import GpsValidation from '../flightTestPage/sections/flightTests/GpsValidation.js';
import MagnetometerValidation from '../flightTestPage/sections/flightTests/MagnetometerValidation.js';
import MagCalibration from '../flightTestPage/sections/flightTests/MagCalibration.js';
import FlightTestItem from '../../components/flightTestItem/FlightTestItem.js';

import MagTestCriteria from './magTestCriteria/magTestCriteria.json';
import { extractTestCriteriaList } from '../../utils/testCriteria';

export interface FlightLogUploadRequest {
	filePath: string;
	url: string;
	mimeType: string;
}

interface MagTestSectionProps {
	hwid: HardwareIdDecoded;
	onDone: () => void;
}

export const magTestKeys: string[] = [
	'runMagCalibration',
	'gpsValidation',
	'magnetometerValidationNorth',
	'magnetometerValidationEast',
	'magnetometerValidationSouth',
	'magnetometerValidationWest'
];

const MagTestSection: React.FC<MagTestSectionProps> = (props) => {
	const NUMBER_OF_TESTS = 6;
	const [isModified, setIsModified] = useState<boolean>(false);
	const [connectionGuid] = useState<string>(() => StringUtils.generateGuid());
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [selectedTest, setSelectedTest] = useState<string>('runMagCalibration');

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		// Reset when we first mount
		setTestResults([]);

		return () => {
			// clean up when we unmount.
			setTestResults([]);
		};
	}, []);

	useEffect(() => {
		setIsModified(!!testResults.length);
	}, [testResults]);

	async function handleDisconnectAndSave() {
		try {
			if (!testResults.length) return;
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			let part = await ApiRequestV1.getPartByNumbers({
				partNumber: props.hwid.partNumber,
				serialNumber: props.hwid.serialNumber
			});
			if (!part) {
				rsToastify.error('Missing Part Information', 'Could not find part to save data to.');
				popupController.close(LoadingPopup);
				return;
			}

			const res = await ApiRequestV1.postResult({ data: JSON.stringify(testResults) });

			let status: CustomTypes.PostTestResultRequest['status'] = testResults.some((res) => !res.passed)
				? 'FAIL'
				: 'PASS';
			if (testResults.length !== NUMBER_OF_TESTS) status = 'INCOMPLETE';

			await ApiRequestV1.postTestResult({
				partId: part.id,
				connectionGuid,
				testName: 'Magnetometer Calibration',
				status,
				hasPassed: testResults.every((res) => res.passed),
				isComplete: testResults.length === NUMBER_OF_TESTS,
				resultId: res.id
			});

			rsToastify.success('Test results saved successfully.', 'Test Results Saved');
			setTestResults([]);
			popupController.close(LoadingPopup);
			props.onDone();
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
			popupController.close(LoadingPopup);
		}
	}

	function renderRightHeaderNode() {
		return (
			<>
				<Button
					look={'containedPrimary'}
					onClick={() => {
						isModified ? handleDisconnectAndSave() : props.onDone();
					}}
				>
					{isModified ? 'Disconnect & Save' : 'Disconnect'}
				</Button>
			</>
		);
	}

	function renderCenterNode() {
		if (!isModified) return <></>;
		let completedCount = testResults.length;
		return (
			<Label variant={'subheader2'} weight={'regular'}>
				{`${completedCount}/${NUMBER_OF_TESTS} completed`}
			</Label>
		);
	}

	function getTestStatus(testName: string): TestStatus {
		const test = testResults.find((test) => test.testName === testName);
		if (!test) return 'PENDING';
		return test.passed ? 'SUCCESS' : 'ERROR';
	}

	function scrollToTest(testId: string) {
		const testElement = document.getElementById(testId);
		if (testElement) {
			testElement.scrollIntoView({
				behavior: 'smooth'
			});
		}
	}

	function renderTestList() {
		let elements: JSX.Element[] = [];
		magTestKeys.forEach((testName) => {
			let testResult = testResults.find((test) => test.testName === testName);
			let status: TestStatus | undefined;
			if (testResult) {
				status = testResult.passed ? 'SUCCESS' : 'ERROR';
			}

			elements.push(
				<FlightTestItem
					key={testName}
					testName={StringUtils.convertCamelCaseToHuman(testName)}
					isSelected={testName === selectedTest}
					onClick={() => {
						setSelectedTest(testName);
						scrollToTest(testName);
					}}
					status={status}
				/>
			);
		});

		return elements;
	}

	return (
		<>
			<PageHeader
				title={'Magnetometer Test'}
				isModified={isModified}
				centerNode={renderCenterNode()}
				rightNode={renderRightHeaderNode()}
			/>
			<Box className={'rsMagTestSection'}>
				<Box className="testList">{renderTestList()}</Box>
				<Box className={'main'}>
					<Box className="testContainer">
						<Box>
							<SelectableInputText
								labelTitle={'Vehicle HWID'}
								control={
									new RsFormControl<string>(
										'main',
										`PN1:${props.hwid.partNumber},REV1:${props.hwid.hardwareRevision},SN1:${props.hwid.serialNumber}`
									)
								}
								isSelected={false}
								isFixed
								onClick={() => {}}
								updateControl={() => {}}
								onBlurOrEnter={async (value, enterPressed) => {
									return 'VALID';
								}}
							/>
							<Box className={'dividerLine'} />
						</Box>
						<Box id="runMagCalibration">
							<Box display={'flex'} justifyContent={'space-between'} mb={16}>
								<Label variant={'h3'} weight={'semiBold'}>
									Run Mag Calibration
								</Label>
								<StatusChip status={getTestStatus('runMagCalibration')} />
							</Box>
							<MagCalibration
								testCriteria={extractTestCriteriaList(MagTestCriteria, 'RunMagCalibration')}
							/>
						</Box>
						<Box id="gpsValidation">
							<Box display={'flex'} justifyContent={'space-between'} mb={16}>
								<Label variant={'h3'} weight={'semiBold'}>
									Gps Validation
								</Label>
								<StatusChip status={getTestStatus('gpsValidation')} />
							</Box>
							<GpsValidation
								snrMinMax={[35, 50]}
								satellitesMinMax={[25, 60]}
								testCriteria={extractTestCriteriaList(MagTestCriteria, 'GpsValidation')}
							/>
						</Box>
						{['North', 'East', 'South', 'West'].map((direction) => (
							<Box key={direction} id={`magnetometerValidation${direction}`}>
								<Box display={'flex'} justifyContent={'space-between'} mb={16}>
									<Label variant={'h3'} weight={'semiBold'}>
										{direction} Heading
									</Label>
									<StatusChip status={getTestStatus(`magnetometerValidation${direction}`)} />
								</Box>
								<MagnetometerValidation
									absHeadingErrorMax={8}
									direction={direction as 'North' | 'South' | 'East' | 'West'}
									testCriteria={extractTestCriteriaList(
										MagTestCriteria,
										`MagnetometerValidation${direction}`
									)}
								/>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</>
	);
};
export default MagTestSection;
