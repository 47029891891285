import { Service } from '../Service';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { rsToastify } from '@redskytech/framework/ui';
import { WebUtils } from '../../utils/utils';
import { partAssemblies, baseParts } from './assembly.data';
import { BasePartType, PartAssemblyType } from './IAssemblyService';

export interface HardwareIdDecoded {
	partId?: number;
	partNumber: string;
	serialNumber: string;
	hardwareRevision: string;
	children?: HardwareIdDecoded[];
	isChild?: boolean;
}

export default class AssemblyService extends Service {
	/**
	 * Converts the hardwareId string to an object of type HardwareIdDecoded
	 * @param hardwareId - The hardwareId string to be decoded with a format of PN1:XXX,REV1:YYY,SN1:ZZZ (e.x. PN1:AV-1234,REV1:1,SN1:123456)
	 * @returns An object of type HardwareIdDecoded or undefined if the hardwareId string is not in the correct format
	 */
	decodeHardwareId(hardwareId: string): HardwareIdDecoded | undefined {
		const hardwareIdSplit = hardwareId.split(',');
		if (hardwareIdSplit.length !== 3) return undefined;

		const hardwareIdDecoded: HardwareIdDecoded = {
			partNumber: '',
			serialNumber: '',
			hardwareRevision: ''
		};
		const partNumberString = hardwareIdSplit.find(
			(value) => value.trim().startsWith('PN:') || value.trim().match(/^PN[1-9]:/gm)
		);
		if (!partNumberString) return undefined;
		hardwareIdDecoded.partNumber = partNumberString.split(':')[1].trim();

		const serialNumberString = hardwareIdSplit.find(
			(value) => value.trim().startsWith('SN:') || value.trim().match(/^SN[1-9]:/gm)
		);
		if (!serialNumberString) return undefined;
		hardwareIdDecoded.serialNumber = serialNumberString.split(':')[1].trim();

		const hardwareRevisionString = hardwareIdSplit.find(
			(value) => value.trim().startsWith('REV:') || value.trim().match(/^REV[1-9]:/gm)
		);
		if (!hardwareRevisionString) return undefined;
		hardwareIdDecoded.hardwareRevision = hardwareRevisionString.split(':')[1].trim();

		if (!hardwareIdDecoded.partNumber || !hardwareIdDecoded.serialNumber || !hardwareIdDecoded.hardwareRevision)
			return undefined;
		return hardwareIdDecoded;
	}

	/**
	 * Generates a hardwareId string with a format of PN1:XXX,REV1:YYY,SN1:ZZZ (e.x. PN1:AV-1234,REV1:1.01,SN1:OAK-123456-0424)
	 * @param partNumber - The part number of the device
	 * @param serialNumber - The serial number of the device
	 * @param hardwareRevision - The hardware revision of the device
	 */
	generateHardwareId(partNumber: string, serialNumber: string, hardwareRevision: string): string {
		return `PN1:${partNumber},REV1:${hardwareRevision},SN1:${serialNumber}`;
	}

	getPartAssemblyTypeFromPartNumber(partNumber: string): PartAssemblyType | undefined {
		let assemblyKey: PartAssemblyType;
		for (assemblyKey in partAssemblies) {
			if (partAssemblies[assemblyKey].partNumbers.includes(partNumber)) return assemblyKey;
		}
		return undefined;
	}

	getAssemblyTypeFromPartNumber(partNumber: string): PartAssemblyType | BasePartType | undefined {
		const partAssemblyType = this.getPartAssemblyTypeFromPartNumber(partNumber);
		if (partAssemblyType) return partAssemblyType;
		const basePartType = this.getBasePartTypeFromPartNumber(partNumber);
		if (basePartType) return basePartType;
		return undefined;
	}

	getLabelFromPartNumber(partNumber: string): string | undefined {
		const partAssemblyType = this.getPartAssemblyTypeFromPartNumber(partNumber);
		if (partAssemblyType) return partAssemblies[partAssemblyType].label;
		const basePartType = this.getBasePartTypeFromPartNumber(partNumber);
		if (basePartType) return baseParts[basePartType].label;
		return undefined;
	}

	isPartAssemblyType(partType: PartAssemblyType | BasePartType | string): partType is PartAssemblyType {
		return partAssemblies.hasOwnProperty(partType);
	}
	isBaseAssemblyType(partType: PartAssemblyType | BasePartType | string): partType is BasePartType {
		return baseParts.hasOwnProperty(partType);
	}
	getLabelFromPartType(partType: PartAssemblyType | BasePartType | string): string {
		// do a type guard check to see which type it is
		if (this.isPartAssemblyType(partType)) {
			return partAssemblies[partType].label;
		} else if (this.isBaseAssemblyType(partType)) {
			return baseParts[partType].label;
		}
		return '';
	}

	getBasePartTypeFromPartNumber(partNumber: string): BasePartType | undefined {
		let basePartKey: BasePartType;
		for (basePartKey in baseParts) {
			if (baseParts[basePartKey].partNumbers.includes(partNumber)) return basePartKey;
		}
		return undefined;
	}

	async lookupPartByNumbers(
		partNumber: string,
		serialNumber: string
	): Promise<Api.V1.Part.By.Numbers.Get.Res | undefined> {
		try {
			return await ApiRequestV1.getPartByNumbers({
				partNumber,
				serialNumber
			});
		} catch (e: any) {
			if (e.response?.status === 404) return undefined;
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up part by numbers'), 'Lookup Error');
		}
		return undefined;
	}
}
