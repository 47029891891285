import { Box, Icon, Label } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import AssemblyService, { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import { KitGroupType, KitResult } from '../../../services/kit/IKitService';
import globalState from '../../../state/globalState';
import AssemblyAuditCheck, { AuditIconMap } from '../../assemblyAuditCheck/AssemblyAuditCheck';
import TestAuditCheck from '../../testAuditCheck/TestAuditCheck';
import './KitCheckoutTable.scss';
import { aurPartNumbers } from '../../../services/assembly/assembly.data';

export interface KitCheckoutTableProps {
	kitGroup: { group: KitGroupType; idList: HardwareIdDecoded[] };
}

const KitCheckoutTable: React.FC<KitCheckoutTableProps> = (props) => {
	const kitResults = useRecoilValue<KitResult[]>(globalState.kitResults);
	const assemblyService = new AssemblyService();
	const [rows, setRows] = useState<JSX.Element[]>([]);
	useEffect(() => {
		(async () => {
			const rows = await renderRows();
			setRows(rows);
		})();
	}, [props.kitGroup]);

	async function getPartId(partNumber: string, serialNumber: string) {
		try {
			let { id } = await ApiRequestV1.getPartByNumbers({
				partNumber,
				serialNumber
			});
			return id;
		} catch (e) {
			console.error(e);
		}
	}

	function getKitCompletionStatus(partNumber: string, serialNumber: string, hardwareRevision: string) {
		let kitResult = kitResults.find(
			(kit) =>
				kit.HWID.partNumber === partNumber &&
				kit.HWID.serialNumber === serialNumber &&
				kit.HWID.hardwareRevision === hardwareRevision
		);
		return !!kitResult ? kitResult.completed : false;
	}

	async function renderRows() {
		let rows: JSX.Element[] = [];

		if (!props.kitGroup.idList.length) return rows;

		function renderRow(part: HardwareIdDecoded): JSX.Element {
			return (
				<tr key={`${part.serialNumber}-${part.partNumber}`}>
					<td>
						<Label variant="caption1" weight="regular" ml={part.isChild ? 25 : 0}>
							{part.serialNumber} - {assemblyService.getLabelFromPartNumber(part.partNumber)}
						</Label>
					</td>
					<td>
						{!!part.partId && !aurPartNumbers.includes(part.partNumber) && (
							<AssemblyAuditCheck partId={part.partId} mode={'ICON'} />
						)}
					</td>
					<td>
						{!!part.partId && !aurPartNumbers.includes(part.partNumber) && (
							<TestAuditCheck partId={part.partId} serialNumber={part.serialNumber} mode={'ICON'} />
						)}
					</td>
					<td>
						<Icon
							{...AuditIconMap[
								getKitCompletionStatus(part.partNumber, part.serialNumber, part.hardwareRevision)
									? 'SUCCESS'
									: 'WARNING'
							]}
						/>
					</td>
				</tr>
			);
		}

		for (let item of props.kitGroup.idList) {
			rows.push(renderRow(item));

			if (item.children && item.children.length) {
				for (let child of item.children) {
					rows.push(renderRow({ ...child, isChild: true }));
				}
			}
		}

		return rows;
	}

	return (
		<Box className="rsKitCheckoutTable">
			<Label variant="h6" weight="bold">
				{props.kitGroup.group}
			</Label>
			<table>
				<thead>
					<tr className="header">
						<th>
							<Label variant="caption2" weight="regular">
								Serial Number
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Assemblies
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Tests
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Kit Checks
							</Label>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</Box>
	);
};

export default KitCheckoutTable;
