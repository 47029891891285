import * as React from 'react';
import './FlightTestSection.scss';
import PayloadVerifyEsadReady from './flightTests/PayloadVerifyEsadReady';
import VehicleHeightSensorTesting from './flightTests/VehicleHeightSensorTesting';
import LoadFlightLog from './flightTests/LoadFlightLog';
import PayloadVideoQuality from './flightTests/PayloadVideoQuality';
import CameraInspection from './flightTests/CameraInspection';

import WindSpeed from './flightTests/WindSpeed';
import RunPreflight from './flightTests/RunPreflight';
import PayloadHighSpeedEngagements from './flightTests/PayloadHighSpeedEngagement';
import LoadVideo from './flightTests/LoadVideo';
import MinWattsProfileFlight from './flightTests/MinWattsProfileFlight';
import ReviewPlottingResults from './flightTests/ReviewPlottingResults';

import FlightTestCriteria from '../flightTestCriteria/trainerPayloadFlightTestCriteria.json';
import { extractTestCriteriaList } from '../../../utils/testCriteria';

export type TrainerPayloadPreFlightTestKey = 'windSpeed' | 'cameraInspection';

export type TrainerPayloadFlightTestKey =
	| 'runPreflight'
	| 'tenMeterHeightSensorTesting'
	| 'verifyEsadReady'
	| 'eightyMeterHeightSensorTesting'
	| 'minWattsProfileFlight'
	| 'highSpeedEngagements';

export type TrainerPayloadPostFlightTestKey =
	| 'loadVideo'
	| 'loadFlightLog'
	| 'reviewPlottingResults'
	| 'payloadVideoQuality';

export type TrainerPayloadPostFlightTestKeyNoVideo = 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type TrainerPayloadTestKey =
	| TrainerPayloadPreFlightTestKey
	| TrainerPayloadFlightTestKey
	| TrainerPayloadPostFlightTestKey;

const trainerPayloadTestKeys: TrainerPayloadTestKey[] = [
	'windSpeed',
	'cameraInspection',
	'runPreflight',
	'tenMeterHeightSensorTesting',
	'verifyEsadReady',
	'eightyMeterHeightSensorTesting',
	'minWattsProfileFlight',
	'highSpeedEngagements',
	'loadVideo',
	'loadFlightLog',
	'reviewPlottingResults',
	'payloadVideoQuality'
];

type AssertAllKeys<T extends readonly string[]> = T[number] extends TrainerPayloadTestKey ? true : never;
const assertAllKeys: AssertAllKeys<typeof trainerPayloadTestKeys> = true;

export { trainerPayloadTestKeys };

export const trainerPayloadPreFlightTestData: { [key in TrainerPayloadPreFlightTestKey]: JSX.Element } = {
	windSpeed: <WindSpeed testCriteria={extractTestCriteriaList(FlightTestCriteria, 'WindSpeed')} />,
	cameraInspection: (
		<CameraInspection testCriteria={extractTestCriteriaList(FlightTestCriteria, 'CameraInspection')} />
	)
};

export const trainerPayloadInFlightTestData: { [key in TrainerPayloadFlightTestKey]: JSX.Element } = {
	runPreflight: (
		<RunPreflight
			key={'trainPreflight'}
			targetPayloadType={'TRAINER_PAYLOAD_ASSEMBLY'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'RunPreflight')}
		/>
	),
	tenMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			testKey={'tenMeterHeightSensorTesting'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'TenMeterHeightSensorTesting')}
		/>
	),
	verifyEsadReady: (
		<PayloadVerifyEsadReady testCriteria={extractTestCriteriaList(FlightTestCriteria, 'VerifyEsadReady')} />
	),

	eightyMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			testKey={'eightyMeterHeightSensorTesting'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'EightyMeterHeightSensorTesting')}
		/>
	),
	minWattsProfileFlight: (
		<MinWattsProfileFlight testCriteria={extractTestCriteriaList(FlightTestCriteria, 'MinWattsProfileFlight')} />
	),
	highSpeedEngagements: (
		<PayloadHighSpeedEngagements
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'HighSpeedEngagements')}
		/>
	)
};

export const trainerPayloadPostFlightTestData: { [key in TrainerPayloadPostFlightTestKey]: JSX.Element } = {
	loadVideo: <LoadVideo testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadVideo')} />,
	loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
	reviewPlottingResults: (
		<ReviewPlottingResults testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')} />
	),
	payloadVideoQuality: (
		<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
	)
};

export const trainerPayloadPostFlightTestDataNoVideo: { [key in TrainerPayloadPostFlightTestKeyNoVideo]: JSX.Element } =
	{
		loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
		reviewPlottingResults: (
			<ReviewPlottingResults
				testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')}
			/>
		),
		payloadVideoQuality: (
			<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
		)
	};

export const trainerPayloadFlightTestData = {
	...trainerPayloadPreFlightTestData,
	...trainerPayloadInFlightTestData,
	...trainerPayloadPostFlightTestData
};

export const trainerPayloadFlightTestDataNoVideo = {
	...trainerPayloadPreFlightTestData,
	...trainerPayloadInFlightTestData,
	...trainerPayloadPostFlightTestDataNoVideo
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
