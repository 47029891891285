import { Page } from '@redskytech/framework/996';
import {
	Box,
	Button,
	Icon,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { WebUtils } from '@redskytech/framework/utils';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PageHeader from '../../components/pageHeader/PageHeader';
import PartTypeSelector from '../../components/partTypeSelector/PartTypeSelector';
import SerialOnlyPartSelector from '../../components/serialOnlyPartSelector/SerialOnlyPartSelector';
import useWarnOnUnsavedChanges from '../../customHooks/useWarnOnUnsavedChanges';
import { ApiRequestV1 } from '../../generated/apiRequests';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';
import LoadingPopup, { LoadingPopupProps } from '../../popups/loadingPopup/LoadingPopup';
import PdfPopup, { PdfPopupProps } from '../../popups/pdfPopup/PdfPopup';
import { autoCreateableAssemblyPartNumbers } from '../../services/assembly/assembly.data';
import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { BasePartType } from '../../services/assembly/IAssemblyService';
import { KitGroupType, KitResult } from '../../services/kit/IKitService';
import { kitGroups } from '../../services/kit/kit.data';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import colors from '../../themes/colors.scss?export';
import router from '../../utils/router';
import './KitCheckoutPage.scss';
import KitCheckoutGroup from './sections/KitCheckoutGroup';
import KitCheckoutSection from './sections/KitCheckoutSection';

interface KitCheckoutPageProps {}

interface KitLists {
	BATTERY: HardwareIdDecoded[];
	CHARGER: HardwareIdDecoded[];
	VEHICLE: HardwareIdDecoded[];
	PAYLOAD: HardwareIdDecoded[];
	FCU_ANTENNA: HardwareIdDecoded[];
	TABLET: HardwareIdDecoded[];
	AUR: HardwareIdDecoded[];
}

enum KitInfoFormKeys {
	KIT_NUMBER = 'kitNumber',
	SALES_ORDER_NUMBER = 'salesOrderNumber'
}

const KitCheckoutPage: React.FC<KitCheckoutPageProps> = (props) => {
	const params = router.getQueryParams<{ kitNumber: string }>([
		{
			key: 'kn',
			default: '',
			type: 'string',
			alias: 'kitNumber'
		}
	]);
	const expectedTestNamesRef = useRef<string[]>([]);
	const kitCheckoutRef = useRef<HTMLDivElement>(null);
	const assemblyService = new AssemblyService();
	const [isModified, setIsModified] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const kitResults = useRecoilValue<KitResult[]>(globalState.kitResults);
	const [kitNumber, setKitNumber] = useState<string>('');
	const [kitSelected, setKitSelected] = useState<boolean>(false);
	const [expandAll, setExpandAll] = useState<boolean>(false);
	const [expansionTrigger, setExpansionTrigger] = useState<boolean>(false);
	const [kitLists, setKitLists] = useState<KitLists>({
		BATTERY: [],
		CHARGER: [],
		VEHICLE: [],
		PAYLOAD: [],
		FCU_ANTENNA: [],
		TABLET: [],
		AUR: []
	});

	const [kitInfoFormGroup, setKitInfoFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(KitInfoFormKeys.KIT_NUMBER, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl(KitInfoFormKeys.SALES_ORDER_NUMBER, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MIN_LENGTH, 'Too short', 2)
			])
		])
	);

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		if (!params.kitNumber) return;
		updateKitNumber(params.kitNumber);
	}, []);

	useEffect(() => {
		// We have an update to the PO or Kit Number,
		// lets try to download the kit
		const localKitNumber = kitNumber;
		if (localKitNumber === '' || localKitNumber === null) return;
		(async function getKit() {
			const currentKit = await ApiRequestV1.getKitByNumbers({ kn: localKitNumber.toString() });
			// update the list of kitLists with the original kit data, and also update the global kitResults object
			if (!currentKit) {
				// There is no old kit, but we still need to set the form with the Kit Number
				setKitInfoFormGroup((prev) => {
					const current = prev.clone();
					current.get(KitInfoFormKeys.KIT_NUMBER).value = kitNumber;
					return current;
				});
				return;
			}
			if (currentKit.checklistResults) {
				const kitlist: { kitResults: KitResult[] } = currentKit.checklistResults as any;
				setRecoilExternalValue(globalState.kitResults, kitlist.kitResults);
			}
			setIsComplete(currentKit.completed);

			// update the kit and sales order number
			let socontrol = kitInfoFormGroup.get(KitInfoFormKeys.SALES_ORDER_NUMBER);
			socontrol.value = currentKit.poNumber;
			setKitInfoFormGroup((prev) => {
				const current = prev.clone();
				current.get(KitInfoFormKeys.KIT_NUMBER).value = kitNumber;
				current.get(KitInfoFormKeys.SALES_ORDER_NUMBER).value = currentKit.poNumber;
				return current;
			});

			// Add all the preexisting items to the list.
			for (let key in currentKit.kitMap) {
				const currentKitObj = currentKit.kitMap[key];
				if (!kitGroups.AUR.partNumbers.includes(currentKit.kitMap[key].partPartNumber)) {
					addAssembly({
						partId: currentKitObj.partId,
						partNumber: currentKitObj.partPartNumber,
						serialNumber: currentKitObj.partSerialNumber,
						hardwareRevision: currentKitObj.partHardwareRev
					});
					continue;
				}

				const { familyTree } = await ApiRequestV1.getPartFamilyTree({ partId: currentKitObj.partId });
				if (!familyTree) continue;

				addAssembly({
					partId: currentKitObj.partId,
					partNumber: currentKitObj.partPartNumber,
					serialNumber: currentKitObj.partSerialNumber,
					hardwareRevision: currentKitObj.partHardwareRev,
					children: familyTree.children.map((part) => {
						return {
							serialNumber: part.serialNumber,
							partNumber: part.partNumber,
							partId: part.id,
							hardwareRevision: part.hardwareRev,
							isChild: true
						};
					})
				});
			}
		})();
	}, [kitNumber]);

	function updateKitForm(control: RsFormControl<any>) {
		setKitInfoFormGroup(kitInfoFormGroup.clone().update(control));
		setIsModified(true);
	}

	async function handleSave(complete: boolean = false) {
		let loadingPopupId = popupController.open<LoadingPopupProps>(LoadingPopup, {});
		if (isComplete) {
			popupController.closeById(loadingPopupId);
			return;
		}

		try {
			// create an array of assembly data mapped from the arrays of hardware ID's, and setting the name field to be the label on the hardwareID
			const kitListsArray = [
				...kitLists.BATTERY,
				...kitLists.CHARGER,
				...kitLists.VEHICLE,
				...kitLists.PAYLOAD,
				...kitLists.FCU_ANTENNA,
				...kitLists.TABLET,
				...kitLists.AUR
			].map((hwid) => {
				return {
					partNumber: hwid.partNumber,
					serialNumber: hwid.serialNumber,
					hardwareRevision: hwid.hardwareRevision,
					name: assemblyService.getLabelFromPartNumber(hwid.partNumber) || ''
				};
			});

			// get the kitResults object and save it into the checklistResults object
			const kitUpdates = {
				checklistResults: { kitResults: kitResults },
				children: kitListsArray,
				completed: complete,
				kitNumber: kitInfoFormGroup.get(KitInfoFormKeys.KIT_NUMBER).value?.toString() || '',
				poNumber: kitInfoFormGroup.get(KitInfoFormKeys.SALES_ORDER_NUMBER).value?.toString() || ''
			};
			if (complete) {
				setIsComplete(true);
			}

			await ApiRequestV1.postKitAssemble(kitUpdates);
			setIsModified(false);
			// Construct the object, just use the list of partnumbers to define the assembly children, and the kitResults to define the checklistResults field.
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
			//popupController.close(LoadingPopup);
		}
		popupController.closeById(loadingPopupId);
	}

	function renderRightHeaderNode() {
		// TODO: fix this saved logic here
		return (
			<Box display={'flex'} gap={8}>
				{!isComplete && (
					<Button
						look={'containedPrimary'}
						onClick={() => {
							handleSave(false);
						}}
					>
						{'Save'}
					</Button>
				)}
				{!isComplete && (
					<Button
						look={'containedPrimary'}
						onClick={() => {
							popupController.open<ConfirmPopupProps>(ConfirmPopup, {
								title: 'Complete Kit',
								message:
									'Completing this kit will lock it for editing, are you sure you want to complete the kit?',
								confirmButtonText: 'Complete',
								closeButtonText: 'Cancel',
								onConfirm: () => {
									handleSave(true);
								},
								onCancel: () => {}
							});
						}}
					>
						{'Complete'}
					</Button>
				)}
				{isComplete && (
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmPopupProps>(ConfirmPopup, {
								title: 'Editing Complete Kit',
								message:
									'This kit was completed and may have been shipped, are you sure you want to edit it?',
								confirmButtonText: 'Edit',
								closeButtonText: 'Cancel',
								onConfirm: () => {
									setIsComplete(false);
								},
								onCancel: () => {}
							});
						}}
					>
						{'Edit Kit'}
					</Button>
				)}
				<Button
					look="containedPrimary"
					onClick={() => {
						popupController.open<PdfPopupProps>(PdfPopup, {
							serialNumber: kitInfoFormGroup.get<string>(KitInfoFormKeys.KIT_NUMBER).value,
							salesOrder: kitInfoFormGroup.get<string>(KitInfoFormKeys.SALES_ORDER_NUMBER).value,
							summaryData: Object.keys(kitLists).map((key) => {
								return { group: key as KitGroupType, idList: kitLists[key as KitGroupType] };
							})
						});
					}}
				>
					Kit Report
				</Button>
			</Box>
		);
	}

	async function addAssembly(hwid: HardwareIdDecoded | undefined) {
		if (!hwid) {
			rsToastify.error('Please scan a hardware identifier before selecting an assembly type');
			return;
		}
		//identify which list it belongs in, and then add it to that list
		// go through all of the kitGroups and check if the part number is in the list
		// make a copy of the kitLists object

		let part = undefined;
		try {
			part = await ApiRequestV1.getPartByNumbers({
				partNumber: hwid.partNumber,
				serialNumber: hwid.serialNumber
			});
		} catch (e) {
			console.error(e);
		}
		try {
			if (!part) {
				// Is the part one we should auto create?
				if (
					autoCreateableAssemblyPartNumbers.includes(hwid.partNumber) ||
					assemblyService.getBasePartTypeFromPartNumber(hwid.partNumber)
				) {
					// No part was found, but it may be a basepart, or an empty assembly, if that is the case, then this is the time that we will make it
					let assemblyRequest: CustomTypes.AssemblyRequest = {
						partNumber: hwid.partNumber,
						hardwareRevision: hwid.hardwareRevision,
						serialNumber: hwid.serialNumber,
						name: assemblyService.getLabelFromPartNumber(hwid.partNumber) || '',
						assemblyTasksChecked: `""`,
						notes: 'Auto Created during Kit Checkout',
						children: []
					};

					try {
						await ApiRequestV1.postPartAssemble(assemblyRequest);

						part = await ApiRequestV1.getPartByNumbers({
							partNumber: hwid.partNumber,
							serialNumber: hwid.serialNumber
						});
					} catch (e) {
						rsToastify.error(
							WebUtils.getRsErrorMessage(e, 'Error Generating assembly for base part.'),
							'Assembly Error'
						);
						return;
					}
				}
			}

			if (!part) {
				rsToastify.error(
					'This base Part was either entered incorrectly, or could not be created.',
					'Part Not Found'
				);
				return;
			}

			// Check if the part is part of a kit group already
			try {
				const kit = await ApiRequestV1.getKitByPartId({ partId: part.id });
				if (kit && kit.kitNumber !== kitInfoFormGroup.get(KitInfoFormKeys.KIT_NUMBER).value) {
					rsToastify.error(`This part Assembly already belongs to a kit group: ${kit.kitNumber}`);
					return;
				}
			} catch (error) {
				//Fail silently
			}
			setKitLists((prev) => {
				const localKitLists = JSON.parse(JSON.stringify(prev));
				let partInList = false;
				for (let key in localKitLists) {
					if (kitGroups[key as KitGroupType].partNumbers.includes(hwid.partNumber)) {
						//rsToastify.error('This hardware identifier has already been added to the list');
						// Only push it if it is not already in the list
						if (
							!localKitLists[key].some(
								(item: HardwareIdDecoded) =>
									item.partNumber === hwid.partNumber &&
									item.serialNumber === hwid.serialNumber &&
									item.hardwareRevision === hwid.hardwareRevision
							)
						) {
							localKitLists[key].push(hwid);
							setIsModified(true);
							partInList = true;
						}
					}
				}
				if (!partInList) {
					rsToastify.error('This hardware identifier does not belong to a kit group');
				}
				return localKitLists;
			});
		} catch (e) {
			rsToastify.error(
				'This part Assembly has either not been prepared, or was entered incorrectly',
				'Assembly not found'
			);
			console.error(e);
		}
	}

	function renderKitInfoHeader() {
		return (
			<Box display={'flex'} alignSelf={'flex-start'} flexDirection={'row'} gap={16}>
				<LabelInputText
					labelTitle={'Kit Number'}
					inputMode={'text'}
					placeholder={'3 characters max'}
					className={'kitInput'}
					disabled
					control={kitInfoFormGroup.get(KitInfoFormKeys.KIT_NUMBER)}
					updateControl={updateKitForm}
				/>
				<LabelInputText
					labelTitle={'Sales Order Number'}
					inputMode={'text'}
					placeholder={'[SO#]'}
					className={'salesOrderInput'}
					control={kitInfoFormGroup.get(KitInfoFormKeys.SALES_ORDER_NUMBER)}
					updateControl={updateKitForm}
				/>
				<Box alignSelf={'flex-end'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							setExpandAll(false);
							setExpansionTrigger((prev) => {
								return !prev;
							});
						}}
					>
						<Icon iconImg={'icon-chevron-up'} fontSize={14} marginRight={8} /> Collapse All
					</Button>
				</Box>
				<Box alignSelf={'flex-end'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							setExpandAll(true);
							setExpansionTrigger((prev) => {
								return !prev;
							});
						}}
					>
						<Icon iconImg={'icon-chevron-down'} fontSize={14} marginRight={8} /> Expand All
					</Button>
				</Box>
			</Box>
		);
	}

	function renderKitGroups() {
		return Object.keys(kitLists).map((key) => {
			if (kitLists[key as KitGroupType].length > 0)
				return (
					<KitCheckoutGroup
						removeItem={removeItemFromList}
						key={key}
						group={key as KitGroupType}
						idList={kitLists[key as KitGroupType]}
						allExpanded={expandAll}
						triggerExpanded={expansionTrigger}
					/>
				);
		});
	}

	function removeItemFromList(group: KitGroupType, hwid: HardwareIdDecoded) {
		setKitLists((prev) => {
			const localKitLists = JSON.parse(JSON.stringify(prev));

			const indexToRemove = localKitLists[group].findIndex(
				(item: HardwareIdDecoded) =>
					item.serialNumber === hwid.serialNumber &&
					item.partNumber === hwid.partNumber &&
					item.hardwareRevision === hwid.hardwareRevision
			);

			if (indexToRemove !== -1) {
				localKitLists[group].splice(indexToRemove, 1);
			}

			// also look through the kitResults and remove the item from there if we deleted an item after checking it
			setRecoilExternalValue(globalState.kitResults, (prevState) => {
				let updatedState = [...prevState];
				const removalIndex = updatedState.findIndex((value) => {
					return (
						value.HWID.partNumber === hwid.partNumber &&
						value.HWID.serialNumber === hwid.serialNumber &&
						value.HWID.hardwareRevision === hwid.hardwareRevision
					);
				});
				if (removalIndex > -1) {
					updatedState.splice(removalIndex, 1);
				}
				return updatedState;
			});
			return localKitLists;
		});
	}

	function updateKitNumber(newNumber: string) {
		setKitNumber(newNumber);
		setKitSelected(true);
		setIsModified(true);
	}

	function renderKitBuildTopSection() {
		// TODO: add logic here to show a button to start a new kit, or continue an old one after entering the number.
		//if (assemblyList.length > 0) { //
		if (kitSelected) {
			return (
				<>
					<PartTypeSelector
						onPartTypeSelected={(assemblyType, hwid) => addAssembly(hwid)}
						pageTitle={'Kit Checkout'}
						clearAfterEnter={true}
						labelText={'Scan hardware identifiers to build the kit.'}
						rightNode={renderRightHeaderNode()}
					/>{' '}
					<SerialOnlyPartSelector
						onPartTypeSelected={(partType, hwid) => addAssembly(hwid)}
						partType={'TABLET' as BasePartType}
						clearAfterEnter={true}
						labelText={'Enter Tablet Serial Below to add a Tablet.'}
						rightNode={renderRightHeaderNode()}
						fullSize={false}
					/>{' '}
					<Box className={'content'} display={'flex'} flexDirection={'column'} gap={16}>
						<Box
							display={'flex'}
							flexDirection={'column'}
							borderBottom={`1px solid ${colors.neutralGrey500}`}
						></Box>
						{/* This section is the fixed Kit Info Header */}
						{renderKitInfoHeader()}
						{renderKitGroups()}
					</Box>
					<Box className={'bottomSpace'} height={60} gap={16} />
				</>
			);
		} else {
			return (
				<>
					<PageHeader title={'Kit Checkout'} />
					<Box className={'content'}>
						<KitCheckoutSection onKitSelected={updateKitNumber}></KitCheckoutSection>
					</Box>
				</>
			);
		}
	}

	return <Page className={'rsKitCheckoutPage'}>{renderKitBuildTopSection()}</Page>;
};
export default KitCheckoutPage;
