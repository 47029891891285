import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface HighSpeedEngagementProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	PROD_ENGAGEMENT = 'confirmProductionEngagementNotification',
	ENGAGEMENT_30_1 = 'performHighSpeedEngage30Deg1',
	ENGAGEMENT_30_2 = 'performHighSpeedEngage30Deg2'
}

const HighSpeedEngagement: React.FC<HighSpeedEngagementProps> = (props) => {
	const TEST_NAME: TestKey = 'highSpeedEngagements';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_1, res ? res.data[FormKeys.ENGAGEMENT_30_1] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.ENGAGEMENT_30_1
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.ENGAGEMENT_30_1}`);
						return false;
					}
					return validateTestCriteria(FormKeys.ENGAGEMENT_30_1, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_2, res ? res.data[FormKeys.ENGAGEMENT_30_2] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.ENGAGEMENT_30_2
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.ENGAGEMENT_30_2}`);
						return false;
					}
					return validateTestCriteria(FormKeys.ENGAGEMENT_30_2, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<boolean>(FormKeys.PROD_ENGAGEMENT, res ? res.data[FormKeys.PROD_ENGAGEMENT] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.PROD_ENGAGEMENT
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.PROD_ENGAGEMENT}`);
						return false;
					}
					return validateTestCriteria(FormKeys.PROD_ENGAGEMENT, control.value, props.testCriteria);
				})
			])
		]);
	}, [props.testCriteria, res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [defineFormGroup]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsHighSpeedEngagement'}>
			<Checkbox
				labelText={'Verify R1GCS notification stating "Production Engagement Enabled"'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.PROD_ENGAGEMENT)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #1'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_1)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #2'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_2)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default HighSpeedEngagement;
