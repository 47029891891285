import * as React from 'react';
import './EvaluationItem.scss';
import { Box, Button, Label } from '@redskytech/framework/ui';

export type EvaluationOptions = 'PASS' | 'FAILURE' | undefined;

export type EvaluationGroup = {
	[key: string]: EvaluationOptions;
};

interface EvaluationItemProps {
	label: string;
	onEvaluationClick: (evaluation: EvaluationOptions | undefined) => void;
	evaluation?: EvaluationOptions;
	resettable?: boolean;
}

const EvaluationItem: React.FC<EvaluationItemProps> = (props) => {
	return (
		<Box className={'rsEvaluationItem'}>
			<Box flexGrow={1}>
				<Label variant={'subheader2'} weight={'regular'}>
					{props.label}
				</Label>
			</Box>
			<Box display={'flex'} gap={16}>
				<Button
					look={props.evaluation === 'FAILURE' ? 'containedPrimary' : 'outlinedPrimary'}
					onClick={() => {
						// this allows us to "unset" the evaluation if it was accidentally clicked
						if (props.resettable && props.evaluation === 'FAILURE') {
							props.onEvaluationClick(undefined);
						} else {
							props.onEvaluationClick('FAILURE');
						}
					}}
					small
				>
					Failure
				</Button>
				<Button
					look={props.evaluation === 'PASS' ? 'containedPrimary' : 'outlinedPrimary'}
					onClick={() => {
						// this allows us to "unset" the evaluation if it was accidentally clicked
						if (props.resettable && props.evaluation === 'PASS') {
							props.onEvaluationClick(undefined);
						} else {
							props.onEvaluationClick('PASS');
						}
					}}
					small
				>
					Pass
				</Button>
			</Box>
		</Box>
	);
};
export default EvaluationItem;
