// This is a simple class to encapsulate storing test criteria in an organized consistent way to be added to web only based tests.

export type OperatorList = 'eq' | 'lt' | 'gt' | 'neq' | 'lte' | 'gte' | 'exists' | 'not exists';

export interface ITestCriteria {
	fieldName: string;
	operator: OperatorList;
	value?: any;
	unit?: string;
	description?: string;
	overrideOnly?: boolean; // This field indicates the criteria is only for override purposes
}

export interface ITestDefinition {
	testName: string;
	testCriteria: ITestCriteria[];
}

export interface TestResult {
	fieldName: string;
	value: any;
}

export function extractTestCriteriaList(testObject: any, testName: string): ITestCriteria[] {
	const testCriteria = testObject?.testSteps?.find(
		(item: ITestDefinition) => item.testName === testName
	)?.testCriteria;
	if (!testCriteria) {
		throw new Error(`Test criteria not found for test: ${testName}`);
	}
	return testCriteria;
}

// Generate test keys from a criteria list
export function extractTestKeys(criteriaList: ITestCriteria[]): string[] {
	// Get the unique list of field names from the criteria list
	return criteriaList.map((item) => item.fieldName);
}

export function applyTestCriteriaComparison(
	compString: OperatorList,
	dataValue: any,
	targetValue: any = null
): boolean {
	const allowedComparisons = new Set(['eq', 'lt', 'gt', 'neq', 'lte', 'gte', 'exists', 'not exists']);

	if (!allowedComparisons.has(compString)) {
		throw new Error(`Invalid comparison type: ${compString}`);
	}

	switch (compString) {
		case 'eq':
			return dataValue === targetValue;
		case 'lt':
			return dataValue < targetValue;
		case 'gt':
			return dataValue > targetValue;
		case 'neq':
			return dataValue !== targetValue;
		case 'lte':
			return dataValue <= targetValue;
		case 'gte':
			return dataValue >= targetValue;
		case 'exists':
			return dataValue !== null && dataValue !== undefined;
		case 'not exists':
			return dataValue === null || dataValue === undefined;
		default:
			throw new Error(`Unsupported comparison type: ${compString}`);
	}
}

export function validateTestCriteria(testName: string, testValue: any, testCriteria: ITestCriteria[]) {
	// Iterate through each criterion in the criteria list
	let errorMessage = '';
	for (const item of testCriteria) {
		// Apply the comparison using the applyComparison method
		if (testName !== item.fieldName) continue;

		const targetValue = item.value;

		if (!applyTestCriteriaComparison(item.operator, testValue, targetValue)) {
			errorMessage += `Error: Field '${item.fieldName}' with value '${testValue}' failed the '${item.operator}' comparison against '${targetValue}'.\n`;
		}
	}
	if (errorMessage !== '') {
		console.log(errorMessage);
	}
	return errorMessage === '';
}

export default class TestCriteria {
	private criteriaList: ITestCriteria[] = [];
	private testData: TestResult[] = [];

	// Add a test criteria to the object
	addTestCriteria(fieldName: string, operator: OperatorList, value: any) {
		this.criteriaList.push({ fieldName, operator, value });
	}

	getTestCriteria(): ITestCriteria[] {
		return this.criteriaList;
	}

	getTestData(): Record<string, any> {
		const data: Record<string, any> = {};
		// Collect test data into a dictionary
		for (const result of this.testData) {
			data[result.fieldName] = result.value;
		}
		return data;
	}

	// Add a test result to the object
	addTestResult(fieldName: string, value: any) {
		this.testData.push({ fieldName, value });
	}

	checkTestResults(): string {
		let errorMessage = '';
		const data: Record<string, any> = {};

		// Collect test data into a dictionary
		for (const result of this.testData) {
			data[result.fieldName] = result.value;
		}

		// Iterate through each criterion in the criteria list
		for (const item of this.criteriaList) {
			const { fieldName, operator, value: targetValue } = item;

			// Check if the field exists in the data
			if (!(fieldName in data)) {
				errorMessage += `Error: Result for '${fieldName}' not found in test data.\n`;
				continue;
			}

			// Apply the comparison using the applyComparison method
			const dataValue = data[fieldName];
			if (!applyTestCriteriaComparison(operator, dataValue, targetValue)) {
				errorMessage += `Error: Field '${fieldName}' with value '${dataValue}' failed the '${operator}' comparison against '${targetValue}'.\n`;
			}
		}

		return errorMessage;
	}
}
