import { rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../generated/apiRequests';
import { fcuTestKeys } from '../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../pages/flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../pages/magTestPage/MagTestSection';
import {
	assemblyStructure,
	fcuPartNumbers,
	livePayloadFlightTestPartNumbers,
	livePayloadPartNumbers,
	trainerPayloadPartNumbers
} from '../services/assembly/assembly.data';
import { BasePartType, PartAssemblyType } from '../services/assembly/IAssemblyService';
import serviceFactory from '../services/serviceFactory';
import { optionalTestFixtures, TestFixtureType } from '../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../services/testFixture/testFixture.data';
import { StringUtils } from './utils';
import test from 'node:test';

export interface AuditTestResultSummary {
	group: string;
	tests: {
		testName: string;
		status: 'PASS' | 'FAIL' | 'INCOMPLETE' | 'MISSING';
		createdOn: string;
		testResultId: number;
	}[];
}

export async function getAllTestDataByPartId(partId: number) {
	let masterTestList: AuditTestResultSummary[] = [];

	async function checkAssemblyTests(partIdToCheck: number): Promise<void> {
		const assemblyService = serviceFactory.get('AssemblyService');
		try {
			let res = await ApiRequestV1.getPart({ partId: partIdToCheck });
			let discoveredAssemblyType =
				assemblyService.getPartAssemblyTypeFromPartNumber(res.partNumber) ||
				assemblyService.getBasePartTypeFromPartNumber(res.partNumber);

			if (!discoveredAssemblyType) {
				rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
				return;
			}

			let officialTestList: string[] = [];
			if (testFixtureTestMap[discoveredAssemblyType as PartAssemblyType]) {
				let fixture: TestFixtureType;
				for (fixture in testFixtureTestMap[discoveredAssemblyType as PartAssemblyType]) {
					if (!optionalTestFixtures.includes(fixture)) {
						for (let test of testFixtureTestMap[discoveredAssemblyType as PartAssemblyType][fixture]!
							.tests) {
							if (test.auditRequired) {
								officialTestList.push(test.testName);
							}
						}
					}
				}
			}

			if (trainerPayloadPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...trainerPayloadTestKeys);
			}

			if (livePayloadFlightTestPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...livePayloadTestKeys);
			}

			if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
				officialTestList.push(...vehicleTestKeys, ...magTestKeys);
			}

			if (fcuPartNumbers.includes(res.partNumber)) {
				officialTestList.push(...fcuTestKeys);
			}

			// We don't need to retrieve test results for assemblies that we don't audit any tests for.
			if (officialTestList.length === 0) return;

			const testResSummary = await ApiRequestV1.getTestResultSummary({ partId: partIdToCheck });
			if (!testResSummary) {
				rsToastify.error('Error checking assembly tests', 'Error');
				return;
			}

			let testPartArray: AuditTestResultSummary['tests'] = [];

			if (officialTestList && testResSummary.summary !== undefined) {
				officialTestList.forEach((officialTestName) => {
					if (testResSummary.summary[officialTestName] === undefined) {
						testPartArray.push({
							testName: StringUtils.convertCamelCaseToHuman(officialTestName),
							status: 'INCOMPLETE',
							testResultId: -1,
							createdOn: ''
						});
					} else {
						testPartArray.push({
							testName: StringUtils.convertCamelCaseToHuman(officialTestName),
							status: testResSummary.summary[officialTestName].status,
							testResultId: testResSummary.summary[officialTestName].testResultId,
							createdOn: testResSummary.summary[officialTestName].createdOn
						});
					}
				});
				masterTestList.push({
					group: `${assemblyService.getLabelFromPartNumber(res.partNumber)}`,
					tests: [...testPartArray]
				});
			}

			const assemblyComponents = assemblyStructure[discoveredAssemblyType as PartAssemblyType];
			if (!assemblyComponents || assemblyComponents.length === 0) return;

			let subassemblyChecklist = assemblyComponents.reduce((obj: any, item: PartAssemblyType | BasePartType) => {
				obj[item] = 'false';
				return obj;
			}, {});

			const childTestPromises = res.children.map(async (child) => {
				let childPartAssemblyType =
					assemblyService.getPartAssemblyTypeFromPartNumber(child.childPartNumber) ||
					assemblyService.getBasePartTypeFromPartNumber(child.childPartNumber);

				if (!childPartAssemblyType) {
					rsToastify.error(
						`Unable to recognize child subassembly type. ${child.childPartNumber}`,
						'Unknown Assembly Type'
					);
					return;
				}

				if (subassemblyChecklist.hasOwnProperty(childPartAssemblyType)) {
					subassemblyChecklist[childPartAssemblyType] = 'true';
				}

				if (discoveredAssemblyType === 'MAIN_BOARD_ASSEMBLY' && childPartAssemblyType.includes('MAIN_BOARD')) {
					return;
				}

				await checkAssemblyTests(child.childId);
			});

			await Promise.all(childTestPromises);
		} catch (error) {
			rsToastify.error('Error during test audit check', 'Error');
			console.error(error);
		}
	}

	await checkAssemblyTests(partId);

	return masterTestList;
}
