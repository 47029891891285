import * as React from 'react';
import { useState, useMemo } from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface FcuCameraInspectionProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	EO_FEED_VISIBLE = 'eoPayloadCameraFeedVisible',
	IR_FEED_VISIBLE = 'irPayloadCameraFeedVisible',
	CAMERA_POINTING = 'cameraPointing'
}

const FcuCameraInspection: React.FC<FcuCameraInspectionProps> = (props) => {
	const TEST_NAME: TestKey = 'cameraInspection';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.EO_FEED_VISIBLE, res ? res.data[FormKeys.EO_FEED_VISIBLE] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.EO_FEED_VISIBLE
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.EO_FEED_VISIBLE}`);
						return false;
					}
					return validateTestCriteria(FormKeys.EO_FEED_VISIBLE, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<boolean>(FormKeys.CAMERA_POINTING, res ? res.data[FormKeys.CAMERA_POINTING] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.CAMERA_POINTING
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.CAMERA_POINTING}`);
						return false;
					}
					return validateTestCriteria(FormKeys.CAMERA_POINTING, control.value, props.testCriteria);
				})
			])
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsCameraInspection'} display={'flex'} flexDirection={'column'} gap={16}>
			<Checkbox
				labelText={'EO payload camera video feed visible on both tablet and laptop'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.EO_FEED_VISIBLE)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Click 4 separate Points on the tablet EO video feed to verify general payload pointing'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.CAMERA_POINTING)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default FcuCameraInspection;
