import * as React from 'react';
import './FlightTestSection.scss';
import PayloadVerifyEsadDetonation from './flightTests/PayloadVerifyEsadDetonation';
import LoadFlightLog from './flightTests/LoadFlightLog';
import PayloadVideoQuality from './flightTests/PayloadVideoQuality';
import CameraInspection from './flightTests/CameraInspection';

import WindSpeed from './flightTests/WindSpeed';
import PayloadHighSpeedEngagements from './flightTests/PayloadHighSpeedEngagement';
import LoadVideo from './flightTests/LoadVideo';
import MinWattsProfileFlight from './flightTests/MinWattsProfileFlight';
import ReviewPlottingResults from './flightTests/ReviewPlottingResults';
import LiveVehicleHeightSensorTesting from './flightTests/LiveVehicleHeightSensorTesting';
import LiveRunPreflight from './flightTests/LiveRunPreflight';

import FlightTestCriteria from '../flightTestCriteria/livePayloadFlightTestCriteria.json';
import { extractTestCriteriaList } from '../../../utils/testCriteria';

export type LivePayloadPreFlightTestKey = 'windSpeed' | 'cameraInspection';

export type LivePayloadFlightTestKey =
	| 'runPreflight'
	| 'tenMeterLiveHeightSensorTesting'
	| 'verifyEsadDetonation'
	| 'minWattsProfileFlight'
	| 'highSpeedEngagements';

export type LivePayloadPostFlightTestKey =
	| 'loadVideo'
	| 'loadFlightLog'
	| 'reviewPlottingResults'
	| 'payloadVideoQuality';

export type LivePayloadPostFlightTestKeyNoVideo = 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type LivePayloadTestKey = LivePayloadPreFlightTestKey | LivePayloadFlightTestKey | LivePayloadPostFlightTestKey;

const livePayloadTestKeys: LivePayloadTestKey[] = [
	'windSpeed',
	'cameraInspection',
	'runPreflight',
	'tenMeterLiveHeightSensorTesting',
	'verifyEsadDetonation',
	'minWattsProfileFlight',
	'highSpeedEngagements',
	'loadVideo',
	'loadFlightLog',
	'reviewPlottingResults',
	'payloadVideoQuality'
];

type AssertAllKeys<T extends readonly string[]> = T[number] extends LivePayloadTestKey ? true : never;
const assertAllKeys: AssertAllKeys<typeof livePayloadTestKeys> = true;

export { livePayloadTestKeys };

export const livePayloadPreFlightTestData: { [key in LivePayloadPreFlightTestKey]: JSX.Element } = {
	windSpeed: <WindSpeed testCriteria={extractTestCriteriaList(FlightTestCriteria, 'WindSpeed')} />,
	cameraInspection: (
		<CameraInspection testCriteria={extractTestCriteriaList(FlightTestCriteria, 'CameraInspection')} />
	)
};

export const livePayloadInFlightTestData: { [key in LivePayloadFlightTestKey]: JSX.Element } = {
	runPreflight: (
		<LiveRunPreflight
			key={'livePreflight'}
			targetPayloadType={'INERT_FRAG_PAYLOAD_ASSEMBLY'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'RunPreflight')}
		/>
	),
	tenMeterLiveHeightSensorTesting: (
		<LiveVehicleHeightSensorTesting
			testKey={'tenMeterLiveHeightSensorTesting'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'TenMeterLiveHeightSensorTesting')}
		/>
	),
	verifyEsadDetonation: (
		<PayloadVerifyEsadDetonation
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'VerifyEsadDetonation')}
		/>
	),

	minWattsProfileFlight: (
		<MinWattsProfileFlight testCriteria={extractTestCriteriaList(FlightTestCriteria, 'MinWattsProfileFlight')} />
	),
	highSpeedEngagements: (
		<PayloadHighSpeedEngagements
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'HighSpeedEngagements')}
		/>
	)
};

export const livePayloadPostFlightTestData: { [key in LivePayloadPostFlightTestKey]: JSX.Element } = {
	loadVideo: <LoadVideo testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadVideo')} />,
	loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
	reviewPlottingResults: (
		<ReviewPlottingResults testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')} />
	),
	payloadVideoQuality: (
		<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
	)
};

export const livePayloadPostFlightTestDataNoVideo: { [key in LivePayloadPostFlightTestKeyNoVideo]: JSX.Element } = {
	loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
	reviewPlottingResults: (
		<ReviewPlottingResults testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')} />
	),
	payloadVideoQuality: (
		<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
	)
};

export const livePayloadFlightTestData = {
	...livePayloadPreFlightTestData,
	...livePayloadInFlightTestData,
	...livePayloadPostFlightTestData
};

export const livePayloadFlightTestDataNoVideo = {
	...livePayloadPreFlightTestData,
	...livePayloadInFlightTestData,
	...livePayloadPostFlightTestDataNoVideo
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
