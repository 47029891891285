import * as React from 'react';
import './ResultsList.scss';
import { Box, BoxProps } from '@redskytech/framework/ui';
import ResultItem from '../resultItem/ResultItem';
import { TestResult } from '../../services/testFixture/ITestFixtureService';
import { CalibrationOutput } from '../../services/calibration/ICalibrationService';
import { ObjectUtils } from '../../utils/utils';

interface ResultsListProps extends BoxProps {
	results: TestResult[] | CalibrationOutput[];
	showRawData?: boolean;
	showTestCriteria?: boolean;
}

const ResultsList: React.FC<ResultsListProps> = (props) => {
	const { results, ...boxProps } = props;

	function getTestCriteria(result: TestResult | CalibrationOutput) {
		if (!props.showTestCriteria || !ObjectUtils.isTestResult(result) || !result.testCriteria) return;
		return result.testCriteria;
	}

	function getRawData(result: TestResult | CalibrationOutput) {
		if (!props.showRawData || !ObjectUtils.isTestResult(result) || !result.rawData) return;
		return result.rawData;
	}

	function getOtherData(result: TestResult | CalibrationOutput): any[] {
		if (!ObjectUtils.isTestResult(result)) return [];
		const excludedKeys = [
			'testName',
			'passed',
			'continuable',
			'data',
			'rawData',
			'timeStamp',
			'error',
			'comment',
			'testCriteria'
		];
		const valuesOfNonExcludedProperties: any[] = [];
		for (const key in result) {
			if (!excludedKeys.includes(key)) {
				let resultObj: any = result;
				valuesOfNonExcludedProperties.push(resultObj[key]);
			}
		}
		return valuesOfNonExcludedProperties;
	}

	function renderResultItems() {
		return results.map((result, index) => {
			if (ObjectUtils.isCalibrationResultOutput(result) || ObjectUtils.isTestResult(result)) {
				return (
					<ResultItem
						key={index}
						name={result.testName}
						hasNotRan={result.hasNotRan}
						passed={result.passed}
						timeStamp={result.timeStamp}
						error={result.error}
						data={result.data}
						comment={result.comment}
						rawData={getRawData(result)}
						testCriteria={getTestCriteria(result)}
						other={getOtherData(result)}
					/>
				);
			}
			if (ObjectUtils.isCalibrationLog(result)) {
				return <ResultItem key={index} name={result.name} timeStamp={result.timeStamp} data={result.message} />;
			}
			return <></>;
		});
	}

	return (
		<Box className={'rsResultsList'} {...boxProps}>
			{renderResultItems()}
		</Box>
	);
};
export default ResultsList;
