import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface HighSpeedBoxFlightProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	MS_30 = 'thirtyMeterPerSecondBoxFlightPerformed'
}

const HighSpeedBoxFlight: React.FC<HighSpeedBoxFlightProps> = (props) => {
	const TEST_NAME: TestKey = 'highSpeedBoxFlight';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	// This will update with the test criteria
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.MS_30, res ? res.data[FormKeys.MS_30] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find((criteria) => criteria.fieldName === FormKeys.MS_30);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.MS_30}`);
						return false;
					}
					return validateTestCriteria(FormKeys.MS_30, control.value, props.testCriteria);
				})
			])
		]);
	}, [props.testCriteria, res]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	// The defineFormgroup callback function will update on test criteria change
	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [defineFormGroup]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsHighSpeedBoxFlight'} display={'flex'} flexDirection={'column'} gap={16}>
			<Checkbox
				labelText={'30 m/s'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MS_30)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default HighSpeedBoxFlight;
