import * as React from 'react';
import {
	Box,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

import WarningPopup, { WarningPopupProps } from '../../../../popups/warningPopup/WarningPopup';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface VehicleRadioRangeTestingProps {
	testCriteria: ITestCriteria[];
}

type ReviewRadioEvaluationGroup = EvaluationGroup & {
	acceptRadioRangeQualityManually: EvaluationOptions | undefined;
};

type EvaluationKey = keyof ReviewRadioEvaluationGroup;

enum FormKeys {
	RSSI_NOSE_IN_VEH_1 = 'rssiVehNoseIn1',
	RSSI_NOSE_IN_VEH_2 = 'rssiVehNoseIn2',
	RSSI_NOSE_IN_VEH_DELTA = 'rssiAbsVehNoseInDelta',
	RSSI_NOSE_IN_FCU_1 = 'rssiFcuNoseIn1',
	RSSI_NOSE_IN_FCU_2 = 'rssiFcuNoseIn2',
	RSSI_NOSE_IN_FCU_DELTA = 'rssiAbsFcuNoseInDelta',

	THROUGHPUT_NOSE_IN = 'throughputFromFcuNoseIn',

	RSSI_NOSE_LEFT_VEH_1 = 'rssiVehNoseLeft1',
	RSSI_NOSE_LEFT_VEH_2 = 'rssiVehNoseLeft2',
	RSSI_NOSE_LEFT_VEH_DELTA = 'rssiAbsVehNoseLeftDelta',
	RSSI_NOSE_LEFT_VEH_AVG = 'rssiVehNoseLeftAvg',
	THROUGHPUT_NOSE_LEFT = 'throughputFromFcuNoseLeft',

	RSSI_NOSE_RIGHT_VEH_1 = 'rssiVehNoseRight1',
	RSSI_NOSE_RIGHT_VEH_2 = 'rssiVehNoseRight2',
	RSSI_NOSE_RIGHT_VEH_DELTA = 'rssiAbsVehNoseRightDelta',
	RSSI_NOSE_RIGHT_VEH_AVG = 'rssiVehNoseRightAvg',
	THROUGHPUT_NOSE_RIGHT = 'throughputFromFcuNoseRight',

	ACCEPT_RADIO_RANGE_QUALITY = 'acceptRadioRangeQualityManually',

	RSSI_SIDE_VEH_AVG_DELTA = 'rssiAbsVehSideAvgDelta'
}

const VehicleRadioRangeTesting: React.FC<VehicleRadioRangeTestingProps> = (props) => {
	const TEST_NAME: TestKey = 'radioRangeTesting';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const [popupState, setPopupState] = useState({ isVisible: false, message: '', title: '' });
	const [evaluations, setEvaluations] = useState<ReviewRadioEvaluationGroup>({
		acceptRadioRangeQualityManually: res ? res.data['acceptRadioRangeQualityManually'] : undefined
	});
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_1,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_2,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_AVG,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_AVG] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						// Check that the form key has a test criteria
						const testCriteria = props.testCriteria.find(
							(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_LEFT_VEH_AVG
						);
						if (!testCriteria) {
							console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_LEFT_VEH_AVG}`);
							return false;
						}
						return validateTestCriteria(FormKeys.RSSI_NOSE_LEFT_VEH_AVG, control.value, props.testCriteria);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_LEFT_VEH_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_LEFT_VEH_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_SIDE_VEH_AVG_DELTA,
				res ? res.data[FormKeys.RSSI_SIDE_VEH_AVG_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.THROUGHPUT_NOSE_LEFT,
				res ? res.data[FormKeys.THROUGHPUT_NOSE_LEFT] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_1, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_FCU_1
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_IN_FCU_1}`);
						return false;
					}
					return validateTestCriteria(FormKeys.RSSI_NOSE_IN_FCU_1, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_2, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_FCU_2
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_IN_FCU_2}`);
						return false;
					}
					return validateTestCriteria(FormKeys.RSSI_NOSE_IN_FCU_2, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_1, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_1
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_IN_VEH_1}`);
						return false;
					}
					return validateTestCriteria(FormKeys.RSSI_NOSE_IN_VEH_1, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_2, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_2
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_IN_VEH_2}`);
						return false;
					}
					return validateTestCriteria(FormKeys.RSSI_NOSE_IN_VEH_2, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_IN_VEH_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						// Check that the form key has a test criteria
						const testCriteria = props.testCriteria.find(
							(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_DELTA
						);
						if (!testCriteria) {
							console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_IN_VEH_DELTA}`);
							return false;
						}
						return validateTestCriteria(FormKeys.RSSI_NOSE_IN_VEH_DELTA, control.value, props.testCriteria);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_IN_FCU_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(FormKeys.THROUGHPUT_NOSE_IN, res ? res.data[FormKeys.THROUGHPUT_NOSE_IN] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_1,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_1] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_2,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_2] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_AVG,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_AVG] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control): boolean => {
						// Check that the form key has a test criteria
						const testCriteria = props.testCriteria.find(
							(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_RIGHT_VEH_AVG
						);
						if (!testCriteria) {
							console.error(`Test criteria not found for ${FormKeys.RSSI_NOSE_RIGHT_VEH_AVG}`);
							return false;
						}
						return validateTestCriteria(
							FormKeys.RSSI_NOSE_RIGHT_VEH_AVG,
							control.value,
							props.testCriteria
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_RIGHT_VEH_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_RIGHT_VEH_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.THROUGHPUT_NOSE_RIGHT,
				res ? res.data[FormKeys.THROUGHPUT_NOSE_RIGHT] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
				res ? res.data[FormKeys.ACCEPT_RADIO_RANGE_QUALITY] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			)
		]);
	}, [props.testCriteria, res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [defineFormGroup]);

	useEffect(() => {
		if (!popupState.isVisible) return;
		let popupId = popupController.open<WarningPopupProps>(WarningPopup, {
			title: popupState.title,
			message: popupState.message,
			confirmButtonText: 'Continue',
			onConfirm: () => {
				setPopupState((prev) => {
					return { isVisible: false, message: '', title: '' };
				});
			}
		});

		return () => {
			if (popupId) {
				popupController.closeById(popupId);
			}
		};
	}, [popupState]);

	function checkRssiValue(rssiValue: number) {
		const rssiValueAbs = Math.abs(rssiValue);
		const rssiThresholdLowAbs = 50;

		if (rssiValueAbs < rssiThresholdLowAbs) {
			setPopupState((prev) => {
				return {
					isVisible: true,
					message: `RSSI value is very high, please inspect test setup. Received -${rssiValue}, expected value lower than -${rssiThresholdLowAbs}`,
					title: 'Comm Fault Warning'
				};
			});
		}
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		// Sanitize the control value for the input in storage
		const newControlValue = testFixtureService.controlValueToNumber(control);

		// sanitize inputs to become numbers, and negative values where appropriate
		if (newControlValue !== undefined && !control.key.includes('throughput')) {
			control.value = Math.abs(newControlValue) * -1.0;
		} else if (newControlValue !== undefined) {
			control.value = newControlValue;
		}

		let cloneGroup = formGroup.clone().update(control);

		// calculate averages and deltas since we are storing them now.

		if (control.key === FormKeys.RSSI_NOSE_LEFT_VEH_1 || control.key === FormKeys.RSSI_NOSE_LEFT_VEH_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_2);
			let controlAvg = cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_AVG);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_DELTA);
			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let avg = (Math.abs(value1) + Math.abs(value2)) / 2.0;
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlAvg.value = Math.abs(avg) * -1.0;
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlAvg);
					cloneGroup.update(controlDelta);
				}
			}
		}

		if (control.key === FormKeys.RSSI_NOSE_RIGHT_VEH_1 || control.key === FormKeys.RSSI_NOSE_RIGHT_VEH_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_2);
			let controlAvg = cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_AVG);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_DELTA);

			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let avg = (Math.abs(value1) + Math.abs(value2)) / 2.0;
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlAvg.value = Math.abs(avg) * -1.0;
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlAvg);
					cloneGroup.update(controlDelta);
				}
			}
		}

		if (control.key === FormKeys.RSSI_NOSE_IN_VEH_1 || control.key === FormKeys.RSSI_NOSE_IN_VEH_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_2);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_DELTA);

			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlDelta);
				}
			}
		}

		if (control.key === FormKeys.RSSI_NOSE_IN_FCU_1 || control.key === FormKeys.RSSI_NOSE_IN_FCU_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_2);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_DELTA);

			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlDelta);
				}
			}
		}

		if (
			cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_DELTA) !== undefined &&
			cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_DELTA) !== undefined
		) {
			let leftDelta = testFixtureService.controlValueToNumber(cloneGroup.get(FormKeys.RSSI_NOSE_LEFT_VEH_DELTA));
			let rightDelta = testFixtureService.controlValueToNumber(
				cloneGroup.get(FormKeys.RSSI_NOSE_RIGHT_VEH_DELTA)
			);

			if (leftDelta !== undefined && rightDelta !== undefined) {
				let delta = Math.abs((leftDelta + rightDelta) / 2.0);
				let sideDelta = cloneGroup.get(FormKeys.RSSI_SIDE_VEH_AVG_DELTA);
				sideDelta.value = Math.abs(delta);
				cloneGroup.update(sideDelta);
			}
		}
		setFormGroup(cloneGroup);
		testFixtureService.validateFlightTest(
			cloneGroup,
			TEST_NAME,
			false,
			FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
			props.testCriteria
		);
	}

	function handleEvaluationClick(evaluation: EvaluationOptions | undefined, key: EvaluationKey) {
		if (key === FormKeys.ACCEPT_RADIO_RANGE_QUALITY) {
			let control = formGroup.get(FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
			if (evaluation === undefined) {
				control.resetToInitial();
			} else {
				control.value = evaluation === 'PASS';
			}
			setFormGroup((prev) => prev.clone().update(control));
		}

		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});

		testFixtureService.validateFlightTest(
			formGroup,
			TEST_NAME,
			false,
			FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
			props.testCriteria
		);
	}

	function handleRssiNegation(event: any, key: string) {
		const currentValue = parseFloat(event.target.value);
		// Check the value with the thresholds
		checkRssiValue(currentValue);
		// Negate the value and set it back to the input
		const negatedValue = (Math.abs(currentValue) * -1).toString();
		let control = formGroup.get(key);
		control.value = negatedValue;
		handleUpdateControl(control);
	}

	function renderTestItem(
		label: string,
		formKeyVehRssi1: FormKeys,
		formKeyVehRssi2: FormKeys,
		formKeyFcuRssi1: FormKeys | undefined,
		formKeyFcuRssi2: FormKeys | undefined,
		formKeyThroughput: FormKeys
	) {
		return (
			<Box key={label} marginBottom={16}>
				<Label weight={'bold'} variant={'body1'}>{`${label}`}</Label>
				<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
					<LabelInputText
						key={`${label}${formKeyVehRssi1}`}
						labelTitle={'RSSI 1 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi1)}
						updateControl={() => {}}
						onBlur={(event) => {
							handleRssiNegation(event, formKeyVehRssi1);
						}}
					/>
					<LabelInputText
						key={`${label}${formKeyVehRssi2}`}
						labelTitle={'RSSI 2 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi2)}
						updateControl={() => {}}
						onBlur={(event) => {
							handleRssiNegation(event, formKeyVehRssi2);
						}}
					/>
					{formKeyFcuRssi1 && formKeyFcuRssi2 && (
						<>
							<LabelInputText
								key={`${label}${formKeyFcuRssi1}`}
								labelTitle={'RSSI 1 from FCU'}
								inputMode={'text'}
								type={'text'}
								control={formGroup.get(formKeyFcuRssi1 ? formKeyFcuRssi1 : '')}
								updateControl={() => {}}
								onBlur={(event) => {
									console.log('onblur rssi1');
									handleRssiNegation(event, formKeyFcuRssi1 ? formKeyFcuRssi1 : '');
								}}
							/>
							<LabelInputText
								key={`${label}${formKeyFcuRssi2}`}
								labelTitle={'RSSI 2 from FCU'}
								inputMode={'text'}
								type={'text'}
								control={formGroup.get(formKeyFcuRssi2 ? formKeyFcuRssi2 : '')}
								updateControl={() => {}}
								onBlur={(event) => {
									handleRssiNegation(event, formKeyFcuRssi2 ? formKeyFcuRssi2 : '');
								}}
							/>
						</>
					)}
					<LabelInputText
						key={`${label}${formKeyThroughput}`}
						labelTitle={'Throughput from FCU'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyThroughput)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box></Box>
			</Box>
		);
	}

	return (
		<Box className={'rsRadioRangeTesting'} key={TEST_NAME}>
			{[
				renderTestItem(
					'Nose Left',
					FormKeys.RSSI_NOSE_LEFT_VEH_1,
					FormKeys.RSSI_NOSE_LEFT_VEH_2,
					undefined,
					undefined,
					FormKeys.THROUGHPUT_NOSE_LEFT
				),
				renderTestItem(
					'Nose In',
					FormKeys.RSSI_NOSE_IN_VEH_1,
					FormKeys.RSSI_NOSE_IN_VEH_2,
					FormKeys.RSSI_NOSE_IN_FCU_1,
					FormKeys.RSSI_NOSE_IN_FCU_2,
					FormKeys.THROUGHPUT_NOSE_IN
				),
				renderTestItem(
					'Nose Right',
					FormKeys.RSSI_NOSE_RIGHT_VEH_1,
					FormKeys.RSSI_NOSE_RIGHT_VEH_2,
					undefined,
					undefined,
					FormKeys.THROUGHPUT_NOSE_RIGHT
				)
			]}
			{user?.role === 'admin' && (
				<EvaluationItem
					label={'Radio Range Testing manually reviewed and approved'}
					resettable={true}
					onEvaluationClick={(evaluation) => {
						console.log(evaluation);
						handleEvaluationClick(evaluation, FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
					}}
					evaluation={evaluations.acceptRadioRangeQualityManually}
				/>
			)}
		</Box>
	);
};
export default VehicleRadioRangeTesting;
