import React, { useEffect, useState } from 'react';
import './Menu.scss';
import MenuItem from './menuItem/MenuItem';
import router from '../../utils/router';
import { Label, Box, Icon } from '@redskytech/framework/ui';
import classNames from 'classnames';
import serviceFactory from '../../services/serviceFactory';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { ApiRequestV1 } from '../../generated/apiRequests';

const Menu: React.FC = () => {
	const [user, setUser] = useRecoilState<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const [currentPath, setCurrentPath] = useState<string>('');

	useEffect(() => {
		let id = router.subscribeToAfterRouterNavigate((path) => {
			setCurrentPath(path);
		});
		setCurrentPath(router.getCurrentPath());
		return () => {
			router.unsubscribeFromAfterRouterNavigate(id);
		};
	}, []);

	function isSelected(pathBase: string) {
		return currentPath.startsWith(pathBase);
	}

	function handleLogout() {
		const userService = serviceFactory.get('UserService');
		userService.logout();
	}

	async function handleMenuExpand() {
		if (!user) return;
		let isMenuExpanded = !user.isMenuExpanded;
		setUser({ ...user, isMenuExpanded });
		await ApiRequestV1.patchUserMe({ isMenuExpanded });
	}

	return (
		<Box className={`rsMenu ${user?.isMenuExpanded ? '' : 'collapsed'}`}>
			<MenuItem
				isSelected={isSelected('/assembly')}
				name={'Assembly Records'}
				path={'/assembly/list'}
				iconName={'icon-list'}
			/>
			<MenuItem
				isSelected={isSelected('/assemble')}
				name={'Assemble'}
				path={'/assemble'}
				iconName={'icon-tools'}
			/>
			<MenuItem isSelected={isSelected('/test')} name={'Test'} path={'/test'} iconName={'icon-clipboard'} />
			<MenuItem
				isSelected={isSelected('/tablet-setup')}
				path={'/tablet-setup'}
				name={'Tablet Setup'}
				iconName={'icon-android-tablet'}
			/>
			{user?.role !== 'ngTechnician' && (
				<>
					<MenuItem
						isSelected={isSelected('/calibration')}
						name={'Board Calibration'}
						path={'/calibration'}
						iconName={'icon-board'}
					/>
					<MenuItem
						isSelected={isSelected('/mag-test')}
						name={'Magnetometer Test'}
						path={'/mag-test'}
						iconName={'icon-board'}
					/>
					<MenuItem
						isSelected={isSelected('/flight/test')}
						name={'Flight Test'}
						path={'/flight/test'}
						iconName={'icon-flight'}
					/>
					<MenuItem
						isSelected={isSelected('/kit/checkout')}
						name={'Kit Checkout'}
						path={'/kit/checkout'}
						iconName={'icon-order'}
					/>
					<MenuItem
						isSelected={isSelected('/kit/list')}
						name={'Kit Records'}
						path={'/kit/list'}
						iconName={'icon-list'}
					/>
					<MenuItem
						isSelected={isSelected('/label')}
						name={'Labels'}
						path={'/label'}
						iconName={'icon-qr-code'}
					/>
					<MenuItem
						isSelected={isSelected('/report')}
						name={'Reports'}
						path={'/report'}
						iconName={'icon-chart'}
					/>
				</>
			)}
			{user?.role === 'admin' && (
				<>
					<MenuItem
						isSelected={isSelected('/user')}
						name={'Users'}
						path={'/user/list'}
						iconName={'icon-account-circle'}
					/>
					<MenuItem
						isSelected={isSelected('/settings')}
						name={'Settings'}
						path={'/settings'}
						iconName={'icon-settings'}
					/>
				</>
			)}
			<Box className={classNames('rsMenuItem', 'logout')} onClick={handleLogout}>
				<Icon iconImg={'icon-logout'} fontSize={16} />
				<Label variant={'body1'} weight={'regular'}>
					Log Out
				</Label>
			</Box>
			<Icon
				iconImg={user?.isMenuExpanded ? 'icon-double-chevron-left' : 'icon-double-chevron-right'}
				fontSize={16}
				onClick={handleMenuExpand}
				className={'expander'}
				cursorPointer
			/>
		</Box>
	);
};

export default Menu;
