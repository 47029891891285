import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface MagSpinProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	MAG_SPIN = 'magSpinPerformed'
}

const MagSpin: React.FC<MagSpinProps> = (props) => {
	const TEST_NAME: TestKey = 'magSpin';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.MAG_SPIN, res ? res.data[FormKeys.MAG_SPIN] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		]);
	}, [res]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsMagSpin'}>
			<Checkbox
				labelText={'Confirm visually that the vehicle stays in 1m diameter during mag spin'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MAG_SPIN)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default MagSpin;
