import * as React from 'react';
import './SelectableInputText.scss';
import { Box, Icon, InputText, Label, RsFormControl } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ICommon } from '@redskytech/framework/common/Interfaces';
import cloneDeep from 'lodash.clonedeep';

export interface SelectableInputTextProps {
	control: RsFormControl<string>;
	updateControl: (control: RsFormControl<string>) => void;
	labelTitle: string;
	isSelected: boolean;
	onBlurOrEnter: (value: string, enterPressed: boolean) => Promise<'VALID' | string>;
	onClick: () => void;
	onInputInfoClick?: () => void;
	isFixed?: boolean;
	isValidated?: boolean;
	showError?: boolean;
	errorMessage?: string;
	placeholder?: string;
}

const SelectableInputText: React.FC<SelectableInputTextProps> = (props) => {
	// Changed to controlled component
	const [errorMessage, setErrorMessage] = useState<string>(props.errorMessage || '');
	const randomId = useMemo(() => 'input-' + Math.random().toString(36).substring(2, 15), []);

	useEffect(() => {
		if (!props.control.value) setErrorMessage('');
	}, [props.control]);

	useEffect(() => {
		if (props.errorMessage === undefined) return;
		setErrorMessage(props.errorMessage);
	}, [props.errorMessage]);

	useEffect(() => {
		if (!props.isSelected) return;
		const inputElement = document.querySelector(`#${randomId} input`) as HTMLInputElement;
		inputElement.focus();
		inputElement.setSelectionRange(0, inputElement.value.length);
	}, [props.isSelected]);

	function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Tab') {
			e.preventDefault();
		} else if (e.key === 'Enter') {
			e.preventDefault();
			validateValue(true);
		}
	}

	function handleClearClicked() {
		const updatedControl = cloneDeep(props.control);
		updatedControl.value = '';
		setErrorMessage('');
		props.updateControl(updatedControl);
	}

	async function validateValue(enterPressed: boolean) {
		const status = await props.onBlurOrEnter(props.control.value, enterPressed);
		if (status === 'VALID') setErrorMessage('');
		else setErrorMessage(status);
	}

	function getInputIcons(): ICommon.NewIconProps[] {
		let icons: ICommon.NewIconProps[] = [];
		if (errorMessage)
			icons.push({
				position: 'RIGHT',
				iconImg: 'icon-warning-filled',
				color: colors.accentErrorDark
			});

		if (props.onInputInfoClick)
			icons.push({
				marginLeft: icons.length > 0 ? 16 : 0,
				position: 'RIGHT',
				iconImg: 'icon-solid-info-circle',
				color: colors.neutralGrey600,
				cursorPointer: true,
				onClick: props.onInputInfoClick
			});

		if (props.isValidated)
			icons.push({
				marginLeft: icons.length > 0 ? 16 : 0,
				position: 'RIGHT',
				iconImg: 'icon-check-circle',
				color: colors.accentSuccess,
				cursorPointer: true,
				onClick: props.onInputInfoClick
			});
		if (props.showError)
			icons.push({
				marginLeft: icons.length > 0 ? 16 : 0,
				position: 'RIGHT',
				iconImg: 'icon-exclamation-circle',
				color: colors.accentError,
				cursorPointer: true,
				onClick: props.onInputInfoClick
			});

		return icons;
	}

	function handleClick() {
		if (!props.isFixed) props.onClick();
	}

	return (
		<Box className={'rsSelectableInputText'}>
			<Box
				className={classNames('selectableContent', { isSelected: props.isSelected, isFixed: props.isFixed })}
				onClick={handleClick}
			>
				<Label variant={'body1'} weight={'regular'} mb={8}>
					{props.labelTitle}
				</Label>
				<Box display={'flex'} gap={8} alignItems={'center'}>
					<InputText
						id={randomId}
						readOnly={!!props.isFixed}
						inputMode={'text'}
						type={'text'}
						placeholder={props.placeholder ? props.placeholder : 'Scan hardware identifier'}
						control={props.control}
						updateControl={(control) => props.updateControl(control as RsFormControl<string>)}
						onKeyDown={(e) => handleKeyDown(e)}
						onBlur={() => validateValue(false)}
						icon={getInputIcons()}
					/>
					{!!props.control.value && !props.isFixed && (
						<Icon
							iconImg={'icon-delete'}
							cursorPointer
							className={'deleteIcon'}
							onClick={handleClearClicked}
						/>
					)}
				</Box>
			</Box>
			{errorMessage && (
				<Label variant={'caption1'} weight={'regular'} color={colors.accentError} mt={4}>
					{errorMessage}
				</Label>
			)}
		</Box>
	);
};

export default SelectableInputText;
