import { LabelInputText, Paper, Popup, PopupProps } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { HwidFormModel } from '../../pages/labelPage/LabelPage';
import './ScanBarcodePopup.scss';

export interface ScanBarcodePopupProps extends PopupProps {
	onScan: (barcode: string, data: Omit<HwidFormModel, 'quantity'>) => void;
}

const ScanBarcodePopup: React.FC<ScanBarcodePopupProps> = (props) => {
	const popupElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		(document.querySelector('.rsScanBarcode input') as HTMLInputElement)?.focus();
	}, [popupElementRef]);

	function handleScan(scan: string) {
		const regex = /^PN1:([A-Za-z0-9\-._]+),REV1:([^,]+),SN1:([A-Za-z]+)-([A-Za-z]?)(\d+)-(\d{2})(\d{2})$/;
		const match = scan.match(regex);
		if (!match) return;

		const [, partNumber, hwRev, location, prefix, startingNumber, week, year] = match;

		let data: Omit<HwidFormModel, 'quantity'> = {
			partNumber,
			hwRev,
			location,
			prefix: prefix || '',
			startingNumber,
			week,
			year
		};

		props.onScan(scan, data);
	}

	return (
		<Popup {...props}>
			<Paper className="rsScanBarcode">
				<LabelInputText
					elementRef={popupElementRef}
					inputMode="text"
					labelTitle={'Scan Barcode'}
					onChange={handleScan}
				/>
			</Paper>
		</Popup>
	);
};

export default ScanBarcodePopup;
